import { Controller } from "stimulus";

export default class extends Controller {

  //  TODO: Generate target dynamically
  static targets = [
    'new_patientsPoint', 'new_patientsFullTime', 'new_patientsHaldTime', 'new_patientsToalPayout',
    'productionPoint', 'productionFullTime', 'productionHaldTime', 'productionToalPayout',
    'hyg_visitsPoint', 'hyg_visitsFullTime', 'hyg_visitsHaldTime', 'hyg_visitsToalPayout'
  ];

  connect() {
    console.log("connection to stats")
    document.addEventListener('updateStats', this.handleStats.bind(this))
  }

  disconnect() {
    document.removeEventListener('updateStats', this.handleStats.bind(this))
  }

  handleStats(event) {
    console.log('event dispatched')
    console.log('updating stats...', event)
  }
}
