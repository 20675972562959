(->
  window.EquipmentService or  (window.EquipmentService = {})

  EquipmentService.init = ->
    $ ->
      $('#service_provider-existing-radio').change ->
        if $(this).prop("checked")
          $('#service_provider-existing').removeClass('d-none')
          $('#service_provider-new').addClass('d-none')
          $("#service_provider-new input").prop('disabled', true)
          $("#service_provider-existing input").prop('disabled', false)

      $('#service_provider-new-radio').change ->
        if $(this).prop("checked")
          $('#service_provider-new').removeClass('d-none')
          $('#service_provider-existing').addClass('d-none')
          $("#service_provider-existing input").prop('disabled', true)
          $("#service_provider-new input").prop('disabled', false)

).call this
