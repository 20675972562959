( ->
  class ServiceProposal
    handle_has_presentation_fields = () ->
      $('#presentation-type-id').val("has_presentation")
      $('#has_presentation_fields :input').prop('disabled', false)
      $("#has_presentation_fields, #has_presentation_footer").removeClass('d-none')
      $('#no_presentation_fields :input').prop('disabled', true)
      $("#no_presentation_fields, #no_presentation_footer").addClass('d-none')

    handle_no_presentation_fields = () ->
      $('#presentation-type-id').val("no_presentation")
      $('#no_presentation_fields :input').prop('disabled', false)
      $('#no_presentation_fields :input:not(#service_proposal_no_presentation_notes)').prop('required', true)
      $("#no_presentation_fields, #no_presentation_footer").removeClass('d-none')
      $('#has_presentation_fields :input').prop('disabled', true)
      $("#has_presentation_fields, #has_presentation_footer").addClass('d-none')

    handle_client_decision_fields = () ->
      if $("#service_proposal_client_decision_type").val() == 'scheduled'
        $('#client-decision-notes').removeClass('d-none')
        $('#client_decision_scheduled_fields :input').prop('disabled', false)
        $("#client_decision_scheduled_fields, #transitoin_scheduled_footer").removeClass('d-none')
        $('#client_schedules_button').prop('disabled', false)
        $('#transitoin_deffered_footer').addClass('d-none')
        handle_appointment_fields()
        $('#appointments-list').click ->
          handle_appointment_fields()
      else if $("#service_proposal_client_decision_type").val() == 'deferred'
        $('#client_decision_scheduled_fields, #transitoin_scheduled_footer').addClass('d-none')
        $('#transitoin_deffered_footer').removeClass('d-none')
        $('#client-decision-notes').removeClass('d-none')
        $('#client_decision_scheduled_fields :input').prop('disabled', true)
        $('#client_schedules_button').prop('disabled', true)

      if $("#service_proposal_client_decision_type").val() == 'Accept'
        $("#client_decision_event").val('client_approves_case')
      else if $("#service_proposal_client_decision_type").val() == 'Reject'
        $("#client_decision_event").val('client_rejects_case')

    handle_appointment_fields = () ->
      if $('#appointments-list :input').prop('checked')
        $('#client-decision-notes').removeClass('d-none')
        $('#client_decision_scheduled_fields :input').prop('disabled', false)
        $('#client_decision_scheduled_fields :input').prop('required', true)
        $('#client_schedules_button').prop('disabled', false)

    presentation_type: ->
      if $('#presentation-type-id').val() == "has_presentation"
        handle_has_presentation_fields()
      else if $('#presentation-type-id').val() == "no_presentation"
        handle_no_presentation_fields()

      $('#presentation_type_radio_group').change ->
        if $("#service_proposal_presentation_type_has_presentation").prop("checked")
          handle_has_presentation_fields()
        else if $("#service_proposal_presentation_type_no_presentation").prop("checked")
          handle_no_presentation_fields()

    validate_submit: ->
      $('form').submit ->
        form = $(this)
        $(this).find('input[type="submit"], button[type="submit"]').each (index) ->
          $(this).clone(false).removeAttr('id').prop('disabled', true).insertBefore $(this)
          $(this).hide()
          form.prepend $(this)
          return
        return

    client_decision: ->
      $('#client_decision_scheduled_fields :input').prop('disabled', true)
      $('#client-decision-notes').addClass('d-none')
      $('#service_proposal_client_decision_type').change ->
        handle_client_decision_fields()
      if $('#service_proposal_client_decision_type')
        handle_client_decision_fields()

    case_acceptance_leaderboard: ->
      provider_selection_type_array = ['doctor', 'front_desk', 'hygienist', 'exam_type']
      toggle_leaderboard_selection_type_fields = ->
        provider_selection_type = $('#case-acceptance-leaderboard-dropdown').val()
        $('#case-acceptance-' + provider_selection_type).show()

        hidden_provider_selection_type_array = provider_selection_type_array.filter((value, index, arr) -> value != provider_selection_type)
        $((hidden_provider_selection_type_array.map (x) -> "#case-acceptance-" + x).toString()).hide()

      toggle_leaderboard_selection_type_fields()
      $('#case-acceptance-leaderboard-dropdown').change ->
        toggle_leaderboard_selection_type_fields()


  window.ServiceProposal = new ServiceProposal();
).call this
