import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "payInFullDiscountOutput",
    "payInFullDiscountInput",
    "payInFullDiscountPreference",
    "payInFullDiscountPercentageInput",
    "payInFullDue",
    "payInFullPayment",
    "payInFullDescription",
    "totalDollars",
    "depositThenBalanceDepositInput",
    "depositThenBalanceDepositPreference",
    "depositThenBalanceDepositPercentageInput",
    "depositThenBalanceRemainingInput",
    "depositThenBalanceDepositOutput",
    "depositThenBalanceDescription",
    "minimumDepositPercentage",
    "depositThenBalanceDue",
    "merchantFeeAssignmentType",
    "patientMerchantFeeOutput",
    "practiceMerchantFeeOutput",
    "payInFullTotalDue",
    "depositThenBalanceDepositInstallmentDues",
    "depositThenBalanceBalanceInstallmentDues",
    "practiceDepositMerchantFeeOutput",
    "patientDepositMerchantFeeOutput",
    "practiceBalanceMerchantFeeOutput",
    "patientBalanceMerchantFeeOutput",
    "paymentScheduleTypeOutput",
    "paymentScheduleTypeInput",
    "paymentDeliveryTypeInput",
    "totalMerchantFeeOutput",
    "merchantFeePercentage",
    "merchantFeePercentageOutput",
    "patientDollars",
    "insuranceDollars",
    "customPrice",
    "customScheduleFields",
    "merchantFeeOption",
    "merchantFeePriceOutput",
    "merchantFeeDescription",
    "treatmentPlanPatientDollars",
    "treatmentPlanInsuranceDollars",
    "txpLineItemFields",
    "txpTitleField",
    "thirdPartyDescription",
    "sixMonthFinancingAmount",
    "sixMonthFinancingMerchantFee",
    "sixMonthFinancingDescription",
    "sixMonthFinancingCheckbox",
    "twelveMonthFinancingAmount",
    "twelveMonthFinancingMerchantFee",
    "twelveMonthFinancingDescription",
    "twelveMonthFinancingCheckbox",
    "eighteenMonthFinancingAmount",
    "eighteenMonthFinancingMerchantFee",
    "eighteenMonthFinancingDescription",
    "eighteenMonthFinancingCheckbox",
    "twentyFourMonthFinancingAmount",
    "twentyFourMonthFinancingMerchantFee",
    "twentyFourMonthFinancingDescription",
    "twentyFourMonthFinancingCheckbox",
  ]

  connect() {
    this.updateTotalAmount = this.updateTotalAmount.bind(this);
    $(this.element).on("change", (event) => {
      if (!this.hasCustomScheduleFieldsTarget || !this.customScheduleFieldsTarget.contains(event.target)) {
        this.updateTotalAmount();
      }
    });
    this.updateTotalAmount();
    $(this.txpLineItemFieldsTarget).on('cocoon:after-insert', this.updateTotalAmount)
    $(this.txpLineItemFieldsTarget).on('cocoon:after-remove', this.updateTotalAmount)

    $(this.txpLineItemFieldsTarget).on('cocoon:after-remove', function (e, removedItem) {
      removedItem.find(':input[required]').removeAttr('required');
    });

    if (this.hasTxpTitleFieldTarget) {
      this.setTitle = this.setTitle.bind(this);
      $(this.element).on('cocoon:after-insert', this.setTitle)
      $(this.element).on('cocoon:after-remove', this.setTitle)
      this.setTitle()
    }
    this.handleMerchantFee();
  }

  payInFullCalculations() {
    const payInFullDiscountInputValue = parseFloat(this.payInFullDiscountInputTarget.value) || 0
    const totalDollarsValue = parseFloat(this.totalDollarsTarget.value) || 0
    const inPercentage = totalDollarsValue == 0 ? 0 : (payInFullDiscountInputValue / totalDollarsValue) * 100
    if (this.hasPayInFullDiscountPercentageInputTarget) this.payInFullDiscountPercentageInputTarget.value = inPercentage.toFixed(1)

    const payAfterDiscount = totalDollarsValue - payInFullDiscountInputValue
    this.payInFullDueTarget.innerHTML = `$${totalDollarsValue}`;
    this.payInFullDiscountOutputTarget.innerHTML = `-$${payInFullDiscountInputValue}`;
    this.payInFullPaymentTarget.innerHTML = `$${payAfterDiscount}`;
    const discountMessage = payInFullDiscountInputValue > 0
      ? `<span style="color: #00A300; font-weight: bold; margin-left: 5px;"><em> Discount $${payInFullDiscountInputValue.toFixed(2)}</em></span>`
      : '';
    this.payInFullDescriptionTarget.innerHTML = `Pay: $${payAfterDiscount.toFixed(2)} ${discountMessage}`;
  }

  payInFullDiscountPercentageCalculations() {
    const payInFullDiscountPercentageInputValue = parseFloat(this.payInFullDiscountPercentageInputTarget.value) || 0
    const totalDollarsValue = parseFloat(this.totalDollarsTarget.value) || 0
    const inNumber = (totalDollarsValue * payInFullDiscountPercentageInputValue / 100).toFixed(0)
    this.payInFullDiscountInputTarget.value = inNumber;
    const payAfterDiscount = totalDollarsValue - inNumber
    this.payInFullDueTarget.innerHTML = `$${totalDollarsValue}`;
    this.payInFullDiscountOutputTarget.innerHTML = `-$${inNumber}`;
    this.payInFullPaymentTarget.innerHTML = `${payAfterDiscount}`;
    const discountMessage = inNumber > 0
      ? `<span style="color: #00A300; font-weight: bold; margin-left: 5px;"><em> Discount ${payInFullDiscountPercentageInputValue.toFixed(1)}%</em></span>`
      : '';
    this.payInFullDescriptionTarget.innerHTML = `Pay: $${payAfterDiscount.toFixed(2)} ${discountMessage}`;
  }

  thirdPartyCalculations() {
    const totalDollarsValue = parseFloat(this.totalDollarsTarget.value) || 0;
    let lowestAmount = Infinity

    const financingOptions = [
      { months: 6, amountTarget: this.sixMonthFinancingAmountTarget, descriptionTarget: this.sixMonthFinancingDescriptionTarget, checkboxTarget: this.sixMonthFinancingCheckboxTarget, merchantFeeTarget: this.sixMonthFinancingMerchantFeeTarget },
      { months: 12, amountTarget: this.twelveMonthFinancingAmountTarget, descriptionTarget: this.twelveMonthFinancingDescriptionTarget, checkboxTarget: this.twelveMonthFinancingCheckboxTarget, merchantFeeTarget: this.twelveMonthFinancingMerchantFeeTarget },
      { months: 18, amountTarget: this.eighteenMonthFinancingAmountTarget, descriptionTarget: this.eighteenMonthFinancingDescriptionTarget, checkboxTarget: this.eighteenMonthFinancingCheckboxTarget, merchantFeeTarget: this.eighteenMonthFinancingMerchantFeeTarget },
      { months: 24, amountTarget: this.twentyFourMonthFinancingAmountTarget, descriptionTarget: this.twentyFourMonthFinancingDescriptionTarget, checkboxTarget: this.twentyFourMonthFinancingCheckboxTarget, merchantFeeTarget: this.twentyFourMonthFinancingMerchantFeeTarget }
    ];

    financingOptions.forEach(option => {
      const { months, amountTarget, descriptionTarget, checkboxTarget, merchantFeeTarget } = option;
      if (amountTarget && descriptionTarget) {
        let amount = (totalDollarsValue / months)

        if (merchantFeeTarget) {
          const merchantFee = parseFloat(merchantFeeTarget.value) || 0;
          amount = amount + ((amount * merchantFee) / 100)
        }

        amount = amount.toFixed(2)
        amountTarget.value = amount;
        if (amount < lowestAmount && checkboxTarget.checked) { lowestAmount = amount; }
        descriptionTarget.innerHTML = `$${amount} / mo`;
      }
    });

    const checkedOptions = financingOptions.filter(option => option.checkboxTarget && option.checkboxTarget.checked);
    const checkedMonths = checkedOptions.map(option => option.months);

    if (checkedMonths.length === 0) {
      this.thirdPartyDescriptionTarget.innerHTML = `None`;
    }
    else if (checkedMonths.length === 1) {
      this.thirdPartyDescriptionTarget.innerHTML = `Pay $${lowestAmount} for ${checkedMonths[0]} months`;
    } else {
      this.thirdPartyDescriptionTarget.innerHTML = `${checkedMonths.slice(0, -1).join(', ')} or ${checkedMonths.slice(-1)} months as low as $${lowestAmount} / mo`;
    }
  }


  depositThenBalanceCalculations() {
    const depositThenBalanceDepositInputValue = parseFloat(this.depositThenBalanceDepositInputTarget.value) || 0;
    const totalDollarsValue = parseFloat(this.totalDollarsTarget.value);
    const depositThenBalanceRemainingValue = totalDollarsValue - depositThenBalanceDepositInputValue;
    const inPercentage = totalDollarsValue == 0 ? 0 : (depositThenBalanceDepositInputValue / totalDollarsValue) * 100
    this.depositThenBalanceDepositPercentageInputTarget.value = inPercentage.toFixed(2)

    this.depositThenBalanceRemainingInputTarget.value = depositThenBalanceRemainingValue
    this.depositThenBalanceDepositOutputTarget.innerHTML = `$${depositThenBalanceDepositInputValue}`;
    this.depositThenBalanceDueTarget.innerHTML = `$${depositThenBalanceRemainingValue}`;
    this.depositThenBalanceDescriptionTarget.innerHTML = `Deposit: $${depositThenBalanceDepositInputValue} Today, $${depositThenBalanceRemainingValue} at next appointment`;
  }

  depositThenBalancePercentageCalculations() {
    const depositThenBalanceDepositPercentageInputValue = parseFloat(this.depositThenBalanceDepositPercentageInputTarget.value) || 0;
    const totalDollarsValue = parseFloat(this.totalDollarsTarget.value);
    const inNumber = (totalDollarsValue * depositThenBalanceDepositPercentageInputValue / 100).toFixed(0)

    const depositThenBalanceRemainingValue = totalDollarsValue - inNumber;
    this.depositThenBalanceDepositInputTarget.value = inNumber
    this.depositThenBalanceRemainingInputTarget.value = depositThenBalanceRemainingValue
    this.depositThenBalanceDepositOutputTarget.innerHTML = `$${inNumber}`;
    this.depositThenBalanceDueTarget.innerHTML = `$${depositThenBalanceRemainingValue}`;
    this.depositThenBalanceDescriptionTarget.innerHTML = `Deposit: $${inNumber} (${depositThenBalanceDepositPercentageInputValue.toFixed(1)}%), $${depositThenBalanceRemainingValue} at next appointment`;
  }


  handleChange(e) {
    const selector = e.target.id.replace("payment_delivery_type_", "").replace("_fee_desc", "");

    if (e.target.value === "in_office") {
      $(`#merchantFeeAssignmentType_${selector}_container :input`).prop("disabled", true);
      $(`#merchantFeeAssignmentType_${selector}_container`).addClass("d-none");
    } else {
      $(`#merchantFeeAssignmentType_${selector}_container :input`).prop("disabled", false);
      $(`#merchantFeeAssignmentType_${selector}_container`).removeClass("d-none");
    }
  }

  handleMerchantFee() {
    if (!this.hasTotalDollarsTarget &&
      !this.hasTotalMerchantFeeTarget &&
      !this.hasMerchantFeePercentageTarget &&
      !this.hasTotalMerchantFeeOutputTarget &&
      !this.hasMerchantFeePercentageOutputTarget &&
      !this.hasMerchantFeePriceOutputTarget &&
      !this.hasMerchantFeeOptionTarget
    ) return;

    let totalMerchantFeeOutput = this.totalMerchantFeeOutputTarget
    let merchantFeePercentageOutput = this.merchantFeePercentageOutputTarget
    let merchantFeePriceOutput = this.merchantFeePriceOutputTarget
    let merchantFeeDescription = this.merchantFeeDescriptionTarget

    let totalDollars = this.totalDollarsTarget.value
    let merchantFeePercentage = this.merchantFeePercentageTarget.value
    let merchantFeeType = $(this.merchantFeeOptionTarget).find('input[type="radio"]:checked').val()

    // Sanitize values
    totalDollars = parseFloat(totalDollars)
    merchantFeePercentage = parseFloat(merchantFeePercentage)

    switch (merchantFeeType) {
      case 'practice': merchantFeePercentage = 0
        break;
      case 'share': merchantFeePercentage /= 2
        break;
    }

    totalMerchantFeeOutput.innerHTML = converter.convertFloatToCurrency(merchantFeePercentage * totalDollars)
    merchantFeePriceOutput.innerHTML = converter.convertFloatToCurrency(totalDollars)
    merchantFeePercentageOutput.innerHTML = (merchantFeePercentage * 100).toFixed(2) + '%'
    merchantFeeDescription.innerHTML = `Patient pays ${converter.convertFloatToCurrency(merchantFeePercentage * totalDollars)}`
  }

  updateTotalAmount() {
    if (!this.hasPatientDollarsTarget && !this.hasInsuranceDollarsTarget) return;

    let patientDollars = 0
    let insuranceDollars = 0

    $(this.patientDollarsTargets).filter(':visible').each(function () {
      patientDollars += converter.convertCurrencyToFloat($(this).val()) || 0;
    });

    $(this.insuranceDollarsTargets).filter(':visible').each(function () {
      insuranceDollars += converter.convertCurrencyToFloat($(this).val()) || 0;
    });

    if (this.hasTreatmentPlanPatientDollarsTarget) this.treatmentPlanPatientDollarsTarget.value = patientDollars.toFixed(2);
    if (this.hasTreatmentPlanInsuranceDollarsTarget) this.treatmentPlanInsuranceDollarsTarget.value = insuranceDollars.toFixed(2);
    if (!this.hasTotalDollarsTarget) return;

    this.totalDollarsTarget.value = patientDollars.toFixed(2);
    const goalElements = this.element.querySelectorAll('[data-goal-value]');

    goalElements.forEach((element) => {
      element.setAttribute('data-goal-value', patientDollars.toFixed(2));
      const tempValue = $(element).val()
      $(element).val(0)
      $(element).trigger('keyup');
      $(element).val(tempValue)
      $(element).trigger('keyup');
    });

    if (this.hasPayInFullDiscountInputTarget) {
      const payInFullDiscountPreference = this.payInFullDiscountPreferenceTarget.value || 'flat_rate'
      if (payInFullDiscountPreference == 'flat_rate') { this.payInFullCalculations(); }
      else { this.payInFullDiscountPercentageCalculations(); }
    }

    if (this.hasDepositThenBalanceDepositInputTarget) {
      const depositThenBalanceDepositPreference = this.depositThenBalanceDepositPreferenceTarget.value || 'flat_rate'
      if (depositThenBalanceDepositPreference == 'flat_rate') { this.depositThenBalanceCalculations(); }
      else { this.depositThenBalancePercentageCalculations(); }
    }

    if (this.hasSixMonthFinancingAmountTarget) this.thirdPartyCalculations();

    this.handleMerchantFee();
    if (this.hasCustomScheduleFieldsTarget) {
      this.customPriceTarget.value = patientDollars.toFixed(2);
      var event = new CustomEvent('input', { bubbles: true, cancelable: true });
      this.customPriceTarget.dispatchEvent(event);
    }
  }
  setTitle() {
    if (!this.hasPatientDollarsTarget && !this.hasInsuranceDollarsTarget) return;

    const txpTitleField = this.txpTitleFieldTarget;
    const txpLineItemsSize = $(this.patientDollarsTargets).filter(':visible').length;

    if (txpLineItemsSize <= 1) {
      txpTitleField.classList.add('d-none');
      txpTitleField.querySelectorAll('input').forEach(input => {
        input.disabled = true;
        input.required = false;
      });
    } else {
      txpTitleField.classList.remove('d-none');
      txpTitleField.querySelectorAll('input').forEach(input => {
        input.disabled = false;
        input.required = true;
      });
    }
  }
}
