( ->
  class LabCase
    manage_conditions: ->
      document.addEventListener 'turbo:load', ->
        $('form').on 'click', '.remove_fields', (event) ->
          $(this).closest('.filter-row').remove()
          event.preventDefault()

        $('form').on 'click', '.add_fields', (event) ->
          time = new Date().getTime()
          regexp = new RegExp($(this).data('id'), 'g')
          $(this).before($(this).data('fields').replace(regexp, time))
          event.preventDefault()

    labCaseTracking = () ->
      if $('#date-tracking-id').val() == "appointment"
        $('#track_by_manual :input').prop('disabled', true)
        $("#track_by_manual").addClass('d-none');
        $('#track_by_appointment :input').prop('disabled', false)
        $('#track_by_appointment :input').prop('required', true)
        $("#track_by_appointment").removeClass('d-none')
        $('#track_by_manual :input').prop('disabled', true)
        $('#track_by_manual :input').prop('required', false)
        $("#track_by_manual").addClass('d-none')
      else if $('#date-tracking-id').val() == "manual"
        $('#track_by_appointment :input').prop('disabled', true)
        $("#track_by_appointment").addClass('d-none')
        $('#date-tracking-id').val("manual")
        $('#track_by_manual :input').prop('disabled', false)
        $('#track_by_manual :input').prop('required', true)
        $("#track_by_manual").removeClass('d-none')
        $('#track_by_appointment :input').prop('disabled', true)
        $('#track_by_appointment :input').prop('required', true)
        $("#track_by_appointment").addClass('d-none')

    init: ->

    date_tracker_type: ->
      labCaseTracking()

      $('#track_by_radio_group').change ->
        if $("#lab_case_date_tracking_type_appointment").prop("checked")
          $('#date-tracking-id').val("appointment")

        else if $("#lab_case_date_tracking_type_manual").prop("checked")
          $('#date-tracking-id').val("manual")

        labCaseTracking()

      $('form').on 'submit', (event) ->
        if ($('#date-tracking-id').val() == "appointment" and $("#appointments-list :input").length < 1) or
            ($('#date-tracking-id').val() == "manual" and $("#datepicker_appointment_at :input").val() == "")

          event.preventDefault()
          $(this).find('button[type=submit]').attr('disabled', false);
          $("#modal-errors").html("  <div class='alert alert-danger' id='error_explanation'><ul><li class='text-xxxs'>Select Appointment Time</li></ul></div>")


  window.LabCase = new LabCase();
).call this
