import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["frame", "progressBar"];
  static values = {
    ids: Array,
    time: Number
  };

  connect() {
    // Start the iteration process
    this.iterateIds();
    this.timeValue = parseFloat(this.timeValue) || 0;

  }

  progressBarTargetConnected(element) {
    const time = 10.0 / (this.timeValue - 1);

    const interval = setInterval(() => {
      updateProgressBar(element, time);

    }, 100);
    this.progressIntervalId = interval;
  }

  iterateIds() {
    const ids = this.idsValue;
    const time = this.timeValue * 1000;

    let index = 0;
    let that = this

    const interval = setInterval(() => {
      const id = ids[index];

      const turbo_frame = that.frameTarget

      if (turbo_frame) {
        let src = $(turbo_frame).attr('src');
        const searchParams = new URLSearchParams(src.split('?')[1])
        searchParams.set('goal_reward_id', id);

        src = src.split('?')[0] + '?' + searchParams.toString();
        turbo_frame.innerHTML = Shared.get_loader();
        turbo_frame.setAttribute("src", null);
        turbo_frame.setAttribute("src", src);
      }


      index = (index + 1) % ids.length;
    }, time);

    this.intervalId = interval;
  }

  disconnect() {
    clearInterval(this.intervalId);
    clearInterval(this.progressIntervalId);
  }
}

function updateProgressBar(element, percentage) {
  const progressBar = element
  let currentPercentage = parseFloat(progressBar.getAttribute('aria-valuenow'));
  percentage += currentPercentage
  progressBar.style.width = percentage + '%';
  progressBar.setAttribute('aria-valuenow', percentage);
}
