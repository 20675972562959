import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tableContainer", "progressBar"];
  
  connect() {
    this.startScrolling();
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.startScrolling(); // Restart scrolling after resize/zoom
  }  

  startScrolling() {
    document.querySelectorAll('.custom-scroll-table-container').forEach((element) => {
      element.scrollTop = 0;
      this.scrollElement(element);
    });
  }

  scrollElement(element) {
    const scrollSpeed = 40; // Speed of scroll (lower is faster)
    const pauseDuration = 2000; // Pause duration at top and bottom (in ms)
  
    let isScrollingDown = true;
  
    const scroll = () => {
      if (isScrollingDown) {
        if (element.scrollTop + 10 < element.scrollHeight - element.clientHeight) {
          element.scrollTop += 1; // Scroll down
        } else {
          isScrollingDown = false;
          setTimeout(() => {
            element.scrollTo({ top: 0, behavior: 'smooth' });
            setTimeout(() => {
              isScrollingDown = true; // Reset for scrolling down
              scroll(); // Restart the scroll after pause
            }, 500);
          }, pauseDuration);
          return;
        }
      }
  
      setTimeout(scroll, scrollSpeed);
    };
  
    // Start the scrolling after the pause duration
    setTimeout(scroll, pauseDuration);
  }  
}
