(->
  class CaseTracking
    init: ->
      $('button.close').click ->
        $('#remote_collection_details').addClass('d-none')
        return
 


  window.CaseTracking = new CaseTracking()

).call this
