(->
  window.RansackHelpers or  (window.RansackHelpers = {})

  RansackHelpers.init = ->
    $(document).on 'turbo:load', (e) ->
      $('a.state-change').on 'click', ->
        document.getElementById('set_state_val').value = $(this).attr('id')
        e.preventDefault()
        $(this).closest('form').submit()
        return
      return

).call this
