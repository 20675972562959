import Sortable from 'sortablejs'
(->
  class NestableList
    initialize: (target_url, initial_data = false) ->
      target = document.getElementById(target_url);
      this.sortable_constructor(target)

    collective_init: (target_class) ->
      this.sortable_constructor(target) for target in document.getElementsByClassName(target_class);

    sortable_constructor: (element) ->
      Sortable.create element,
        group: 'nested'
        animation: 100
        fallbackOnBody: true
        invertSwap : true
        store:
          get: (sortable) ->
            order = element.dataset.ids
            if order then order.split('|') else []

          set: (sortable) ->
            order = sortable.toArray()
            if element.dataset.url
              $.ajax
                type: 'PATCH'
                url: element.dataset.url
                dataType: 'script'
                data: { ids: order }
                success: (results) ->

  window.NestableList = new NestableList()

).call this
