import { Controller } from "stimulus";

export default class extends Controller {
  updateFrameAttribute(event, attribute) {
    const lazyFrame = this.findLazyFrame();
    if (!lazyFrame) return;

    const value = event.currentTarget.querySelector('input[type="radio"]').value;
    if (!value) return;

    const url = new URL(lazyFrame.src);
    url.searchParams.set(attribute, value);

    lazyFrame.innerHTML = Shared.get_loader();
    lazyFrame.src = url.toString();
  }

  chartType(event) {
    this.updateFrameAttribute(event, 'chart_type');
  }

  roleType(event) {
    this.updateFrameAttribute(event, 'employee_role');
  }

  findLazyFrame() {
    return this.element.closest('turbo-frame[loading="lazy"]');
  }
}
