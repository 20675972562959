import { Controller } from "stimulus"

const ROLES = ['doctor', 'hygenist', 'specialist']

export default class extends Controller {
  static targets = ['lazyFrame']

  handleChange(e) {
    let provider = e.target.value;

    if (ROLES.includes(provider))
      $("#software-id-container").removeClass("d-none")
    else
      $("#software-id-container").addClass("d-none")
  }

  changeEmploymentType(e) {
    let employeeId = e.currentTarget.dataset.employeeId
    let value = e.target.value
    if (!value)
      return

    if (this.hasLazyFrameTarget) { this.lazyFrameTarget.innerHTML = Shared.get_loading(); }

    $.ajax({
      method: 'PATCH',
      dataType: "script",
      url: `/ownership/employees/${employeeId}/employment_status_change`,
      data: {
        employee: {
          employment_type: value
        }
      }
    })
  }
}
