(->
  class Dashboard

    move_to_page = (page_path) ->
      window.location.href = page_path

    init_navigation: () ->
      $('#navigation-control').change ->
        searchParams = new URLSearchParams($(this).val().split('?')[1])
        searchParams.delete('entityable_id')
        searchParams.delete('entityable_type')
        page_path = window.location.pathname + '?' + searchParams.toString()
        move_to_page(page_path)

      $('#view-type-navigation').change ->
        _path = $('#view-type-navigation').val()
        entityable = $('input[type="radio"][name="entityFilter"]:checked').val()

        if entityable?
          [entityable_id, entityable_type] = entityable.split('-')

          searchParams = new URLSearchParams()
          searchParams.set 'entityable_id', entityable_id
          searchParams.set 'entityable_type', entityable_type

          if $(this).val().includes('?')
            _path = $(this).val() + '&' + searchParams.toString()
          else
            _path = $(this).val() + '?' + searchParams.toString()

        $(".page-inner").html Shared.get_loader
        move_to_page(_path)


    set_params = (tab, value) ->
      if 'URLSearchParams' of window
        searchParams = new URLSearchParams(window.location.search)
        searchParams.set tab, value
        newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
        return newRelativePathQuery


    init_date_navigation: () ->
      $('#date-picker').change ->
        $(".page-inner").html Shared.get_loader
        _path = set_params('date', $(this).val())
        move_to_page(_path)

      $('.navigation-control').click ->
        $(".page-inner").html Shared.get_loader
        _path = set_params('date', $(this).children("input").val())
        move_to_page(_path)

      $('#day_navigation').click ->
        $(".page-inner").html Shared.get_loader


    init_enitity_navigation: () ->
      $('input[type="radio"][name="entityFilter"]').change ->
        entityable = $('input[type="radio"][name="entityFilter"]:checked').val()
        [entityable_id, entityable_type] = entityable.split('-')

        searchParams = new URLSearchParams(window.location.search)
        searchParams.set 'entityable_id', entityable_id
        searchParams.set 'entityable_type', entityable_type

        _path = window.location.pathname + '?' + searchParams.toString()

        $(".page-inner").html Shared.get_loader
        move_to_page(_path)

    get_selected_date: () ->
      if 'URLSearchParams' of window
        searchParams = new URLSearchParams(window.location.search)
        return searchParams.get 'date'
      else
        return null


    activeTabSelection: (_selected_tab) ->
      $(".nav-tabs a[href='##{_selected_tab}']").tab('show');


    toggleDateDropdown: (should_hide_dropdown) ->
      if should_hide_dropdown
        $('#date-dropdown').addClass('d-none')
      else
        $('#date-dropdown').removeClass('d-none')


    addOrReplaceTabBy: (new_tab) ->
      _path = set_params('tab', new_tab)
      history.pushState null, '', _path


    updateQueryStringParameter = (uri, key, value) ->
      re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
      separator = if uri.indexOf('?') != -1 then '&' else '?'
      if uri.match(re)
        return uri.replace re, '$1' + key + '=' + value + '$2'
      else
        return uri + separator + key + '=' + value


    addOrReplaceDateBy: (new_date) ->
      for _option in $("#navigation-control option:not(:disabled)")
        $(_option).val(updateQueryStringParameter($(_option).val(), 'date', new_date))

      _path = set_params('date', new_date)
      history.pushState null, '', _path


  window.Dashboard = new Dashboard()

).call this
