import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    if (this.element.dataset.lazyLoadTypeValue) {
      const observer = new IntersectionObserver(this.handleIntersection.bind(this));
      observer.observe(this.element);
    } else { this.loadContent() }
  }

  handleIntersection(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadContent();
        observer.disconnect();
      }
    });
  }

  loadContent() {
    const lazyLoadPath = this.element.dataset.lazyLoadPath
    const lazyLoadTargetId = this.element.dataset.lazyLoadTargetId

    if (lazyLoadPath) {
      $.ajax({
        type: 'GET',
        url: lazyLoadPath,
        dataType: 'script',
        success: function (result) { }
      })

    } else {
      console.error("Lazy load path is not defined.");
    }
  }
}
