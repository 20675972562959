import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    Calendly.initInlineWidget({
      url: 'https://calendly.com/team-care/installation',
      parentElement: document.querySelector('.calendly-inline-widget'),
      prefill: {
        name: this.data.get("nameValue"),
        email: this.data.get("emailValue")
      },
    });
  }
}
