( ->
  class Event
    init: ->
      #hide and show appropriate frequency block
      frequencies_array = ['day', 'week', 'month', 'year']
      toggle_frequency_fields = ->
        selected_value = $('#frequency_select').val()
        $('#' + selected_value).show()
        $('#' + selected_value + ' :input').prop('disabled', false)
        hidden_values_array = frequencies_array.filter((value, index, arr) -> value != selected_value)
        $((hidden_values_array.map (x) -> "#" + x + ' :input ').toString()).prop('disabled', true)
        $((hidden_values_array.map (x) -> "#" + x).toString()).hide()
        toggle_week_fields()
        return


      week_array = ['0', '1', '2', '3', '4', '5', '6']
      toggle_week_fields = () ->
        selected_value = []
        $('#weekly :checkbox:checked').each (i) ->
          selected_value.push $(this).val()
        show_values_array = week_array.filter((value, index, arr) -> selected_value.includes(value) )
        $((show_values_array.map (x) -> "#week_day_" + x + ' :input ').toString()).prop('disabled', false)
        $((show_values_array.map (x) -> "#week_day_" + x).toString()).show()

        hidden_values_array = week_array.filter((value, index, arr) -> !selected_value.includes(value) )
        $((hidden_values_array.map (x) -> "#week_day_" + x + ' :input ').toString()).prop('disabled', true)
        $((hidden_values_array.map (x) -> "#week_day_" + x).toString()).hide()
        return


      toggle_frequency_fields()
      $('#frequency_select').change ->
        toggle_frequency_fields()
        toggle_assignable_fields()


      #hide and show end_type fields
      end_types_array = ['never', 'on_date']
      toggle_end_type_fields = ->
        end_type = $('#end_type_select').val()
        $('#' + end_type).show()

        hidden_end_types_array = end_types_array.filter((value, index, arr) -> value != end_type)
        hidden_end_types_array.map (x) -> $("#" + x + " :input").val(null)
        $((hidden_end_types_array.map (x) -> "#" + x).toString()).hide()
        return


      toggle_end_type_fields()
      $('#end_type_select').change ->
        toggle_end_type_fields()


      #hide and show recurring fields
      has_recurring = $('#has_recurring_switcher').prop('checked')
      if has_recurring
        $("#recurring_fields").show()

      $("#has_recurring_switcher").change ->
        $("#recurring_fields").toggle()
        if $('#has_recurring_switcher').prop('checked')
          $('#recurring_fields :input').prop('disabled', false)
          toggle_assignable_fields()
        else
          $('#recurring_fields :input').prop('disabled', true)
          toggle_assignable_fields()
        toggle_end_type_fields()
        toggle_frequency_fields()


      toggle_assignment_days = (days) ->
        selected_value = days

        show_values_array = week_array.filter((value, index, arr) -> selected_value.includes(value) )
        show_values_array.map (x) -> $("#destroy_flag_" + x).val("false")
        $((show_values_array.map (x) -> "#assignment_field_" + x).toString()).show()

        hidden_values_array = week_array.filter((value, index, arr) -> !selected_value.includes(value) )
        hidden_values_array.map (x) -> $("#destroy_flag_" + x).val("true")
        $((hidden_values_array.map (x) -> "#assignment_field_" + x).toString()).hide()


      single_day_fields = ->
        $("#assignment_field_0").find("label[for=Sun]").text("User")
        toggle_assignment_days ['0']


      daily_fields = ->
        toggle_assignment_days(week_array)


      weekly_fields = ->
        selected_value = []
        $('#weekly :checkbox:checked').each (i) ->
          selected_value.push $(this).val()
        toggle_assignment_days(selected_value)


      non_assignble_fields = ->
        toggle_assignment_days([])


      toggle_assignable_fields = ->
        if $('#has_recurring_assignments_switcher .switcher-input').prop('checked')
          $('#recurring_assignments_fields').show()

          if $('#has_recurring_switcher').prop('checked')
            $("#assignment_field_0").find("label[for=Sun]").text("Sun")
            if $('#frequency_select').val() == 'day'
              daily_fields()
            else if $('#frequency_select').val() == 'week'
              weekly_fields()
            toggle_frequency_fields()
          else
            single_day_fields()
        else
          $('#recurring_assignments_fields').hide()
          non_assignble_fields()
        return


      $("#has_recurring_assignments_switcher .switcher-input").change ->
        toggle_assignable_fields()
      toggle_assignable_fields()


      $("#weekly :input").change ->
        toggle_week_fields()
        toggle_assignable_fields()


  window.Event = new Event();
).call this