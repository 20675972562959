import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table", "tableToggler"];

  connect() {
    if(this.tableTogglerTarget.checked) {
      this.tableTarget.classList.remove('d-none')
    } else {
      this.tableTarget.classList.add('d-none')
    }
  }

  toggleTable(e) {
    if(this.tableTogglerTarget.checked) {
      this.tableTarget.classList.remove('d-none')
    } else {
      this.tableTarget.classList.add('d-none')
    }
  }
}