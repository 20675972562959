import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["type", "tabCard", "option", "tab", "typelink", "panel"]

  connect() {
    this.update_url_path('tab', this.get_tab('tab', this.element).trim())
    this.update_url_path('type', this.get_tab('type', this.element).trim())
  }

  optionTargetConnected(element) {
    $(element).on("click", (event) => {
      this.ChangeUrl(event);
    });
  }

  update_url_path(tab, tab_name) {
    var _path = this.set_params(tab, tab_name)
    history.pushState(null, '', _path)
  }

  set_params(tab, tab_name) {
    var searchParams = new URLSearchParams(window.location.search)
    searchParams.set(tab, tab_name)
    var newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`
    return newRelativePathQuery
  }

  get_tab(tab, element) {
    var searchParams = new URLSearchParams(window.location.search)
    var tab_name = searchParams.get(tab)

    if (!tab_name) { tab_name = $(element).data(tab) }
    return tab_name
  }

  ChangeUrl(e) {
    this.resetComponent()

    var type = $(e.currentTarget).data('type')
    var tab = $(e.currentTarget).data('tab')
    var parentTab = $(e.currentTarget).data('parentTab')

    $(`#${tab}`).tab('show');
    this.update_url_path('tab', tab)
    this.update_url_path('type', type)

    $(`.${type}, .${tab}, .${parentTab}`).removeClass('d-none');
    $(`.${type}, .${tab}, .${parentTab}`).addClass('show active');
  }

  resetComponent() {
    $(this.panelTargets).removeClass('show active');
    $(this.typeTargets).removeClass('show active');
    $(this.tabTargets).removeClass('show active');
    $(this.optionTargets).removeClass('show active');
    $(this.typelinkTargets).removeClass('show active');
    $(this.tabCardTargets).addClass('d-none');
  }
}
