import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["goalType", "incentiveType", "incentiveRadio"]

  connect() {
    if (this.hasGoalTypeTarget) {
      $(this.goalTypeTarget).select2();
      $(this.goalTypeTarget).on("select2:select", this.handleGoalType);
      this.handleGoalType();
    }
  }

  handleGoalType = () => {
    const selectedGoalId = $(this.goalTypeTarget).val()
    const incentiveRadio = this.incentiveRadioTarget
    if (selectedGoalId) {
      $(incentiveRadio).removeClass('d-none')
      $(incentiveRadio).find('input').attr('disabled', false);
    } else {
      $(incentiveRadio).addClass('d-none')
      $(incentiveRadio).find('input').attr('disabled', true);
      $(this.incentiveRadioTarget).find('input[type="radio"]:checked').val(null);
    }
    this.handleIncentiveType()
  }

  handleIncentiveType = () => {
    this.incentiveTypeTargets.forEach(element => {
      $(element).addClass('d-none')
      $(element).find('input').attr('disabled', true);
    });

    // Show the selected incentive type section
    const selectedType = $(this.incentiveRadioTarget).find('input[type="radio"]:checked').val();

    const selectedSection = document.getElementById(selectedType);
    if (selectedSection) {
      $(selectedSection).removeClass('d-none')
      $(selectedSection).find('input').attr('disabled', false);
    }
  }
}
