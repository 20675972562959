import { Controller } from 'stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ['sortable'];

  connect() {
    datetime_picker.range_input("date_picker");
    $("select:not(.no-select2)").select2();

    $("select#fonticon").select2({
      templateResult: this.formatOptionStyle
    });
    this.initializeSortable();
  }

  initializeSortable() {
    this.sortableTargets.forEach((item) => {
      Sortable.create(item, {
        sort: true,
        animation: 100,
        group: 'nested',
        multiDrag: true,
        selectedClass: 'active',
        onAdd: (evt) => {
          this.handleAddResponse(evt.items.length ? evt.items : [evt.item]);
        }
      });
    });
  }

  handleAddResponse(items) {
    let flagArray = ['true', 'false'];

    items.forEach((item) => {
      const flagElement = $(item).find(".destroy_flag");
      const flagValue = flagElement.val();
      const index = flagArray.indexOf(flagValue);

      if (index !== -1) {
        const nextIndex = (index + 1) % flagArray.length;
        const nextValue = flagArray[nextIndex];
        flagElement.val(nextValue);
        $(item).removeClass(`destroy_flag_${flagValue}`)
        $(item).addClass(`destroy_flag_${nextValue}`)
      }
    });
  }

  formatOptionStyle (state) {
    if (!state.id) return state.text;
    let option = $(`<span><i class="${state.id}" aria-hidden="true"></i> ${state.text}</span>`);

    return option;
  };
}
