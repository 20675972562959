import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["viewOptionSelect"];
  connect() {
    const tabGroup = this.element.dataset.tabsGroup || "default";
    this.tabGroupKey = `activeTab:${tabGroup}`;
    const savedTabId = localStorage.getItem(this.tabGroupKey);

    if (savedTabId) {
      const savedTab = this.element.querySelector(`.nav-link[href="#${savedTabId}"]`);
      if (savedTab) {
        this.activateTab(savedTab, savedTabId);
      }
    } else {
      const defaultTab = this.element.querySelector('.nav-link');
      if (defaultTab) {
        const defaultTabId = defaultTab.getAttribute('href').substring(1);
        this.activateTab(defaultTab, defaultTabId);
      }
    }
  }

  switchTab(event) {
    event.preventDefault();

    const clickedTab = event.currentTarget;
    const tabId = clickedTab.getAttribute('href').substring(1);
    localStorage.setItem(this.tabGroupKey, tabId);
    this.activateTab(clickedTab, tabId);
  }

  activateTab(clickedTab, tabId) {
    this.deactivateAllTabs();

    clickedTab.classList.add('active');
    document.getElementById(tabId).classList.add('show', 'active');
  }

  deactivateAllTabs() {
    const allTabs = this.element.querySelectorAll('.nav-link');
    const allTabContents = this.element.querySelectorAll('.tab-pane');

    allTabs.forEach(tab => tab.classList.remove('active'));
    allTabContents.forEach(content => content.classList.remove('show', 'active'));
  }
}
