import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    $(this.element).find("select:not(.no-select2)").select2();

    datetime_picker.range_input("transaction_date_picker");
  }
}
