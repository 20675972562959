import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['cancelBtn']
  static values = {
    id: String,
    tab: String
  }

  connect() {
    if (($(this.element).parents('turbo-frame[loading="lazy"]').length < 1) && this.hasCancelBtnTarget)
      this.cancelBtnTarget.classList.add('d-none');

    if (!this.hasIdValue)
      return null;

    this.reloader(this.idValue)
  }

  toggleBack() {
    if ($(this.element).parents('turbo-frame[loading="lazy"]').length < 1)
      return null;

    let id = $(this.element).parents('turbo-frame[loading="lazy"]')[0].id
    this.reloader(id)
  }

  reloader(id) {
    let src = $(`#${id}`).attr('src');

    if (this.tabValue == 'yes') {
      src = Shared.push_param_to_url(src, 'tab')
    }

    $(`#${id}`).attr('src', null);
    $(`#${id}`).attr('src', src);
  }
}
