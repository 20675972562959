( ->
  class Cadence

    Cadence.fetch_communication_scripts_data = (selected_items = []) ->
      if typeof $('.communication-type').val() != 'undefined' && typeof $('#communication-scenario').val() != 'undefined'

        communication_types = []
        $('.communication-type').each ->
          communication_types.push $(this).val()
          return

        communication_scenario = $('#communication-scenario').val()

        $.ajax
          url: "/communication_scripts/filtered_communication_scripts"
          type: "GET"
          dataType: "JSON"
          data: {
              "communication_scenario": communication_scenario,
              "communication_types": communication_types
          }
          success: (data) ->
            results = []
            $.each data, (index) ->
              items = data[index]
              form_options = ''

              $.each items, (items_index) ->
                actual_data = items[items_index]
                if selected_items.length > 0 && actual_data.id == selected_items[index]
                  form_options += '<option selected=true value=\'' + actual_data.id + '\'>' + actual_data.title + '</option>'
                else
                  form_options += '<option value=\'' + actual_data.id + '\'>' + actual_data.title + '</option>'

              results.push form_options

            $('.communication-script').each (i) ->
              $(this).html results[i]

            return
        return

    Cadence.fetch_communication_scripts_data_with_id = (id) ->
      if typeof $("##{id}").val() != 'undefined' && typeof $('#communication-scenario').val() != 'undefined'
        target = id.replace('communication_type', 'communication_script_id')

        communication_type = $("##{id}").val()
        communication_scenario = $('#communication-scenario').val()

        $.ajax
          url: "/communication_scripts/filtered_communication_scripts"
          type: "GET"
          dataType: "JSON"
          data: {
              "communication_scenario": communication_scenario,
              "communication_type": communication_type
          }
          success: (data) ->
            form_options = ''
            $.each data, (index) ->
              items = data[index]
              form_options += '<option value=\'' + items.id + '\'>' + items.title + '</option>'

            $("##{target}").html form_options
            return
        return

    load_communication_scripts_on_picker: ->
      Cadence.fetch_communication_scripts_data()

      $('#communication-scenario').on 'change', ->
        Cadence.fetch_communication_scripts_data()
        return

      $('form').on 'cocoon:after-insert', (e, inserted_item) ->
        $('.communication-type').on 'change', ->
          id = $(this).attr('id')
          Cadence.fetch_communication_scripts_data_with_id(id)
          return

      $('.communication-type').on 'change', ->
          id = $(this).attr('id')
          Cadence.fetch_communication_scripts_data_with_id(id)
          return

    load_communication_scripts: (selected_items = []) ->
      Cadence.fetch_communication_scripts_data(selected_items)

      $('.communication-type').on 'change', ->
          id = $(this).attr('id')
          Cadence.fetch_communication_scripts_data_with_id(id)
          return


  window.Cadence = new Cadence();
).call this
