import { Controller } from "stimulus";

export default class extends Controller {
  static targets = []

  connect() {
    console.log('connected')
  }

  increment(event){
    console.log(event.target)
    console.log(event.target.dataset)
    const { matricValue, target } = event.target.dataset
    console.log('increment called', matricValue, target)
  }

  decrement() {
    console.log('decrement called')
  }
}
