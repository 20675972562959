import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import listPlugin from '@fullcalendar/list'
import rrulePlugin from '@fullcalendar/rrule'
import scrollGridPlugin from '@fullcalendar/scrollgrid'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'

(->
  class EventCalendar
    initialize: (event_url, modal_view = true, initial_view = 'timeGridDay', default_date, hide_headers = false, show_min_max_slots = false, popover_view = false) ->
      fetch('/fullcalendar_license')
        .then((response) -> response.json())
        .then((data) ->
          $.ajax
            type: 'GET'
            url: event_url
            success: ({results, resources, min_slot_time, max_slot_time}) ->
              calendarEl = document.getElementById('calendar')
              calendar = new Calendar(calendarEl,
                schedulerLicenseKey: data.access_key
                nowIndicator: true
                headerToolbar: center: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                plugins: [
                  resourceTimeGridPlugin
                  dayGridPlugin
                  timeGridPlugin
                  interactionPlugin
                  bootstrapPlugin
                  rrulePlugin
                  listPlugin
                  scrollGridPlugin
                ]
                themeSystem: 'bootstrap'
                initialView: initial_view
                resourceOrder: 'op_order',
                resources: if resources then resources
                events: results
                height: 'auto'
                timeZone: 'UTC'
                dayMinWidth: 100
                initialDate: default_date
                slotMinTime: if show_min_max_slots then min_slot_time else "00:00"
                slotMaxTime: if show_min_max_slots then max_slot_time else "24:00"

                eventDidMount: (info) ->
                  eventEl = info.el;

                  $(eventEl).attr('data-modal', true) if modal_view
                  if popover_view
                    $(eventEl).attr('data-toggle', 'popover')
                    $(eventEl).attr('data-trigger', 'hover')
                    $(eventEl).attr('data-html', true)
                    $(eventEl).attr('data-content', info.event.extendedProps.html)
                    $(eventEl).attr('disabled', true)

                eventClick: (info) ->
                  info.jsEvent.preventDefault();
                  $.ajax
                    type: 'GET'
                    url: info.event.url

                resourceLabelDidMount: (arg) ->
                  resourceEl = arg.el;
                  resourcePhantom = arg.resource.extendedProps.phantom;
                  if resourcePhantom
                    resourceEl.style.backgroundColor = 'grey';
              )
              calendar.render()
              custom_styling() if hide_headers
        )
    custom_styling = ->
      $(".fc-header-toolbar").addClass 'd-none'

  window.EventCalendar = new EventCalendar()
).call this
