( ->
  class TreatmentPlan
    handle_builder_fields = () ->
      $('#txp-input-type-field').val('builder')

      $(".txp-input-type-tab-builder").tab('show')
      $(".txp-input-type-tab-builder").show()
      $(".txp-input-type-tab-quick_input").hide()
      $('.txp-input-type-tab-quick_input :input').prop('disabled', true)
      $('.txp-input-type-tab-builder :input').prop('disabled', false)

      element = $('[data-controller="payment-options"]');
      if (element.length > 0)
        element.append('<div class="d-none" data-payment-options-target="connectReloader"></div>');
      console.log("working for builder")

    handle_quick_input_fields = () ->
      $(".txp-input-type-tab-quick_input").tab('show')
      $(".txp-input-type-tab-quick_input").show()
      $(".txp-input-type-tab-builder").hide()
      $('#txp-input-type-field').val('quick_input')
      $('.txp-input-type-tab-quick_input :input').prop('disabled', false)
      $('.txp-input-type-tab-builder :input').prop('disabled', true)
      console.log("working for quick")

    handle_has_presentation_fields = () ->
      $('#presentation-type-id').val("has_presentation")
      $('#has_presentation_fields :input').prop('disabled', false)
      $("#has_presentation_fields, #has_presentation_footer").removeClass('d-none')
      $('#no_presentation_fields :input').prop('disabled', true)
      $("#no_presentation_fields, #no_presentation_footer").addClass('d-none')
      $('#treatment_plan_no_presentation_notes').prop('required', false)
      window.TreatmentPlan.presentation_form_type();
      console.log("working for has presentation")

    handle_no_presentation_fields = () ->
      $('#presentation-type-id').val("no_presentation")
      $('#no_presentation_fields :input').prop('disabled', false)
      $('#no_presentation_fields :input:not(#treatment_plan_no_presentation_notes)').prop('required', true)
      $("#no_presentation_fields, #no_presentation_footer").removeClass('d-none')
      $('#has_presentation_fields :input').prop('disabled', true)
      $('#has_presentation_fields select').prop('disabled', true)
      $("#has_presentation_fields, #has_presentation_footer").addClass('d-none')
      console.log("working for no presentation")

      handle_no_presentation_type_fields()
      $('#treatment_plan_no_presentation_notes').on 'input', () ->
        handle_no_presentation_type_fields();

      $('#no_presentation_type_radio_group').find('input[type="radio"]').change ->
        handle_no_presentation_type_fields()

    handle_no_presentation_type_fields = () ->
      handleNonPresentationTypeContainer()
      requiredValue = $("#no_presentation_type_radio_group").find('input[type="radio"]:checked').val() != 'healthy' and $('#treatment_plan_no_presentation_notes').val().trim() == ''
      $('#no_presentation_footer :button').prop('disabled', requiredValue)


    handleNonPresentationTypeContainer = () ->
      nonPresentationTypeValue = $("#no_presentation_type_radio_group").find('input[type="radio"]:checked').val()
      return if !nonPresentationTypeValue

      $("#no_presentation_type_radio_group input[type='radio']").each (index, element) ->
        if element.value == nonPresentationTypeValue
          $("##{element.value}_container").removeClass('d-none')
        else
          $("##{element.value}_container").addClass('d-none')

    handle_patient_decision_fields = () ->
      if $("#patient_decision_type_radio_group").find('input[type="radio"]:checked').val() == 'scheduled'
        $('#patient_decision_scheduled_fields :input').prop('disabled', false)
        $('#patient_schedules_button').prop('disabled', false)
        $("#patient_decision_scheduled_fields").removeClass('d-none')
        $('#patient_decision_deferred_fields :input, #patient_decision_not_needed_fields :input').prop('disabled', true)
        $('#patient_decision_deferred_fields, #patient_decision_not_needed_fields').addClass('d-none')

        handle_appointment_fields()
        $('#appointments-list').click ->
          handle_appointment_fields()
      else if $("#patient_decision_type_radio_group").find('input[type="radio"]:checked').val() == 'deferred'
        $('#patient_decision_deferred_fields').removeClass('d-none')
        $('#patient_decision_deferred_fields :input').prop('disabled', false)
        $('#patient_decision_deferred_fields :input').prop('required', true)
        $('#patient_decision_scheduled_fields, #patient_decision_not_needed_fields').addClass('d-none')
        $('#patient_decision_scheduled_fields :input, #patient_decision_not_needed_fields :input').prop('disabled', true)
      else if $("#patient_decision_type_radio_group").find('input[type="radio"]:checked').val() == 'not_needed'
        $('#patient_decision_not_needed_fields').removeClass('d-none')
        $('#patient_decision_not_needed_fields :input').prop('disabled', false)
        $('#patient_decision_not_needed_fields :input').prop('required', true)
        $('#patient_decision_scheduled_fields, #patient_decision_deferred_fields').addClass('d-none')
        $('#patient_decision_scheduled_fields :input, #patient_decision_deferred_fields :input').prop('disabled', true)


      if $("#patient_decision_type_radio_group").find('input[type="radio"]:checked').val() == 'scheduled'
        $("#patient_decision_event").val('patient_approves_case')
      else if $("#patient_decision_type_radio_group").find('input[type="radio"]:checked').val() == 'deferred'
        $("#patient_decision_event").val('patient_rejects_case')

    handle_appointment_fields = () ->
      if $('#appointments-list input:checked').length > 0
        $('#patient-decision-notes').removeClass('d-none')
        $('#patient_decision_scheduled_fields :input').prop('disabled', false)
        $('#patient_decision_scheduled_fields :input:not(#notes)').prop('required', true)
        $('#patient_schedules_button').prop('disabled', false)
      else
        $('#patient-decision-notes').addClass('d-none')
        $('#patient_decision_scheduled_fields :input').prop('disabled', true)
        $('#patient_decision_scheduled_fields :input:not(#notes)').prop('required', false)
        $('#patient_schedules_button').prop('disabled', true)

    presentation_type: ->
      if $('#presentation-type-id').val() == "has_presentation"
        handle_has_presentation_fields()
      else if $('#presentation-type-id').val() == "no_presentation"
        handle_no_presentation_fields()

      $('#presentation-type-id').change ->
        if $('#presentation-type-id').val() == "has_presentation"
          handle_has_presentation_fields()
        else if $('#presentation-type-id').val() == "no_presentation"
          handle_no_presentation_fields()

      if $("#treatment_plan_presentation_type_has_presentation").prop("checked")
          handle_has_presentation_fields()
        else if $("#treatment_plan_presentation_type_no_presentation").prop("checked")
          handle_no_presentation_fields()

      $('#presentation_type_radio_group').change ->
        if $("#treatment_plan_presentation_type_has_presentation").prop("checked")
          handle_has_presentation_fields()
        else if $("#treatment_plan_presentation_type_no_presentation").prop("checked")
          handle_no_presentation_fields()


    handle_patient_decision_type_scheduled_fields = () ->
      $('.patient_decision_type_scheduled_card :input').prop('disabled', false)
      $('.patient_decision_type_scheduled_card :input').prop('required', false)
      $('.patient_decision_type_scheduled_card').removeClass('d-none')

      $('#patient_decision_type_deferred_card :input').prop('disabled', true)
      $('#patient_decision_type_deferred_card :input').prop('required', true)
      $('#patient_decision_type_deferred_card').addClass('d-none')

    handle_patient_decision_type_deferred_fields = () ->
      $('.patient_decision_type_scheduled_card :input').prop('disabled', true)
      $('.patient_decision_type_scheduled_card :input').prop('required', true)
      $('.patient_decision_type_scheduled_card').addClass('d-none')

      $('#patient_decision_type_deferred_card :input').prop('disabled', false)
      $('#patient_decision_type_deferred_card :input').prop('required', false)
      $('#patient_decision_type_deferred_card').removeClass('d-none')

    patient_decision_type: ->
      if $("#treatment_plan_patient_decision_type_scheduled").prop("checked")
        handle_patient_decision_type_scheduled_fields()
      else if $("#treatment_plan_patient_decision_type_deferred").prop("checked")
        handle_patient_decision_type_deferred_fields()

      $('#patient_decision_type_radio_group').find('input[type="radio"]').change ->
        if $("#treatment_plan_patient_decision_type_scheduled").prop("checked")
          handle_patient_decision_type_scheduled_fields()
        else if $("#treatment_plan_patient_decision_type_deferred").prop("checked")
          handle_patient_decision_type_deferred_fields()


    handle_presentation_form_type: (selectedTabId) ->
      if selectedTabId == 'builder'
          handle_builder_fields()
        else if selectedTabId == 'quick_input'
          handle_quick_input_fields()


    presentation_form_type: ->
      selectedInputType = $('#txp_input_type_dropdown input[type="radio"]:checked')
      selectedTabId = selectedInputType.val()

      this.handle_presentation_form_type(selectedTabId)
      that = this

      $('#txp_input_type_dropdown input[type="radio"]').on 'change', (e) ->
        selectedInputType = $(e.target)
        selectedTabId = selectedInputType.val()

        $('#txp_input_type_dropdown .icon').prop('class', "icon #{selectedInputType.data('font-icon')}")
        $('#txp_input_type_dropdown .title').html(selectedInputType.data('title'))
        $('#txp_input_type_dropdown .styling').css("background-color", selectedInputType.data('bg-color'));
        $('#txp_input_type_dropdown .styling').css("color", selectedInputType.data('text-color'));

        that.handle_presentation_form_type(selectedTabId)

    patient_decision: ->
      $('#patient_decision_scheduled_fields :input').prop('disabled', true)
      $('#patient-decision-notes').addClass('d-none')
      $('#patient_decision_type_radio_group').find('input[type="radio"]').change ->
        handle_patient_decision_fields()
      if $('#patient_decision_type_radio_group')
        handle_patient_decision_fields()

    case_acceptance_leaderboard: ->
      provider_selection_type_array = ['doctor', 'front_desk', 'hygienist', 'exam_type']
      toggle_leaderboard_selection_type_fields = ->
        provider_selection_type = $('#case-acceptance-leaderboard-dropdown').val()
        $('#case-acceptance-' + provider_selection_type).show()

        hidden_provider_selection_type_array = provider_selection_type_array.filter((value, index, arr) -> value != provider_selection_type)
        $((hidden_provider_selection_type_array.map (x) -> "#case-acceptance-" + x).toString()).hide()

      toggle_leaderboard_selection_type_fields()
      $('#case-acceptance-leaderboard-dropdown').change ->
        toggle_leaderboard_selection_type_fields()

    set_future_appointment: ->
      $("#appointments-list").change ->
        id = $('#appointments-list :input:checked').val()
        $('#treatment_plan_progress_future_appointment').val(id)

    treatment_plan_type: ->
      if $('#assign_treatment_plan_select').val() == "start_new"
        $("#continue_previous").addClass('d-none')

      else if $('#assign_treatment_plan_select').val() == "continue_previous"
        $("#continue_previous").removeClass('d-none')

      $('#assign_treatment_plan_select').change ->
        if $('#assign_treatment_plan_select').val() == "start_new"
          $("#continue_previous").addClass('d-none')
          $("#start_new a")[0].click()

        else if $('#assign_treatment_plan_select').val() == "continue_previous"
          $("#continue_previous").removeClass('d-none')
          $("#continue_previous a")[0].click()

  window.TreatmentPlan = new TreatmentPlan();
).call this
