import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:frame-load", this.handleFrameLoad.bind(this));
  }

  handleFrameLoad(event) {
    const frame = event.target

    if (frame.id.includes('office_bonuses_incentives_summary_section')) {
      const checkbox = document.getElementById('office_bonuses_checkbox')
      $(checkbox).prop('disabled', false);
      this.toggleSections(checkbox);
    }

    if(frame.id.includes("office_goals_incentives_summary_section")) {
      const checkbox = document.getElementById('office_goals_checkbox')
      $(checkbox).prop('disabled', false);
      this.toggleSections(checkbox)
    }
  }

  toggleOfficeBonusesSection() {
    this.toggleSections(checkbox)
  }

  valueChanged(event) {
    this.toggleSections(event.target)
  }

  toggleSections(checkbox) {
    const { checked, dataset: { categoryType } } = checkbox;

    if (checked) {
      $(`.${categoryType}_projection_item`).removeClass('d-none')
      $(`.${categoryType}_earned_item`).addClass('d-none')
    } else {
      $(`.${categoryType}_projection_item`).addClass('d-none')
      $(`.${categoryType}_earned_item`).removeClass('d-none')
    }
  }
}
