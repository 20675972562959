import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    this.checkboxTarget.addEventListener("change", () => this.submitForm());
  }

  submitForm() {
    console.log(this.element);
    const loader = this.element.querySelector(".loader");
    loader.classList.remove("d-none");
    this.element.requestSubmit();
  }
}
