(->
  class Birthday
    initialize: () ->
      changePlacehoder()
      concatinateBirthday()

    concatinateBirthday= () ->
      $('#birthday-fields :input').on 'input', ->
        this.value = this.value.slice(0, this.maxLength) if this.value.length > this.maxLength
        $('#user-birthday').val("#{$('#user-birthday-year').val()}-#{$('#user-birthday-month').val()}-#{$('#user-birthday-day').val()}")

    changePlacehoder= () ->
      placeholder = ""

      $('#birthday-fields :input').hover (->
        placeholder = $(this).attr 'placeholder'
        $(this).next().text $(this).attr('name').charAt(0).toUpperCase() + $(this).attr('name').slice(1)
      ), ->
        $(this).next().text placeholder

  window.Birthday = new Birthday()
).call this
