(->
  window.FrontDeskAppointment or  (window.FrontDeskAppointment = {})

  FrontDeskAppointment.init = ->
    $ ->
      $('#edit-account-prep').click ->
        $("#account-show").addClass('d-none');
        $("#account-update").removeClass('d-none');

      $('#edit-insurrance-prep').click ->
        $("#insurrance-show").addClass('d-none');
        $("#insurrance-update").removeClass('d-none');

).call this
