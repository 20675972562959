(->
  window.Equipment or  (window.Equipment = {})

  Equipment.init = ->
    $ ->
      $("#manufacturer-new :input").prop('disabled', true)
      $('#manufacturer-existing-radio').change ->
        if $(this).prop("checked")
          $('#manufacturer-existing').removeClass('d-none')
          $('#manufacturer-new').addClass('d-none')
          $("#manufacturer-new :input").prop('disabled', true)
          $("#manufacturer-existing :input").prop('disabled', false)
        
      $('#manufacturer-new-radio').change ->
        if $(this).prop("checked")
          $('#manufacturer-new').removeClass('d-none')
          $('#manufacturer-existing').addClass('d-none')
          $("#manufacturer-existing :input").prop('disabled', true)
          $("#manufacturer-new :input").prop('disabled', false)
          
).call this
