import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["collectionType", "treatmentPlanSelect"]

  connect() {
    $(this.element).find("select:not(.no-select2)").removeAttr('id');
    $(this.element).find("select:not(.no-select2)").select2();
    this.toggleTreatmentPlanField = this.toggleTreatmentPlanField.bind(this);
    $(this.collectionTypeTarget).find('input[type="radio"]').on('change', this.toggleTreatmentPlanField)
  }

  toggleTreatmentPlanField() {
    const collectionTypeValue = $(this.collectionTypeTarget).find('input[type="radio"]:checked').val()
    const treatmentPlanSelect = this.treatmentPlanSelectTarget

    if (collectionTypeValue == 'treatment_plan_collection') {
      $(treatmentPlanSelect).removeClass('d-none');
      $(treatmentPlanSelect.getElementsByTagName('select')).prop('disabled', false)
    }
    else {
      $(treatmentPlanSelect).addClass('d-none');
      $(treatmentPlanSelect.getElementsByTagName('select')).prop('disabled', true)
    }
  }
}
