import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["goal", "milestone"]

  connect() {
    $("select:not(.no-select2)").removeAttr('id');
    $("select:not(.no-select2)").select2();

    var that = this
    $(this.goalTarget).on('select2:select', function (e) {
      var e = e
      var setValue = true
      $("select option:selected").each(function () {
        if (($(this).val() == e.params.data.id) && ($(e.currentTarget).find('select')[0] != this.parentElement)) {
          $(e.currentTarget).find(':selected').attr('disabled', 'disabled');
          $(e.currentTarget).find('select').val(null).trigger("change")
          setValue = false
        }
      });
      if (setValue)
        $(that.milestoneTarget).text(e.params.data.element.dataset.goalValue)

    });
  }
}
