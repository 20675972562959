import { Controller } from "stimulus";
import flatpickr from "flatpickr";

function updateTurboFrameWithLoader(turbo_frame, src) {
  // Set the loader content first
  turbo_frame.innerHTML = Shared.get_loader();
  turbo_frame.setAttribute("src", null);  // Reset src temporarily
  turbo_frame.setAttribute("src", src);   // Reload with updated src
}

export default class extends Controller {
  static targets = ["dropDownFilter", "calendarPicker"]

  connect() {
    const datetimePickerId = this.element.dataset.datetimePickerPickerValue || 'appointment_at_picker';
    const dateRangeInput = this.element.querySelector(`#${datetimePickerId}`);

    // Initialize flatpickr with range mode
    flatpickr(dateRangeInput, {
      mode: "range",
      onClose: () => {
        this.changeCalendarPicker({ currentTarget: dateRangeInput });
        dateRangeInput.form.requestSubmit();
      }
    });
  }

  changeCalendarPicker(e) {
    if ($(this.element).parents('turbo-frame[loading="lazy"]').length < 1) return null;

    const turbo_frame = $(this.element).parents('turbo-frame[loading="lazy"]')[0];
    let src = $(turbo_frame).attr('src');

    // Extract start_date and end_date from the flatpickr input value
    const dateRange = e.currentTarget.value.split(" to ");
    if (dateRange.length === 2) {
      const [start_date, end_date] = dateRange;

      // Modify URL with start_date and end_date as separate parameters
      const searchParams = getSearchParams(src);
      searchParams.set('start_date', start_date);
      searchParams.set('end_date', end_date);
      
      src = src.split('?')[0] + '?' + searchParams.toString();
    }

    updateTurboFrameWithLoader(turbo_frame, src);
  }

  closeDropdown(e) {
    updateTurboFrameWithLoader(turbo_frame, src);
  }
}

// Helper function for extracting and updating search parameters
function getSearchParams(url) {
  const searchParams = new URLSearchParams(url.split('?')[1]);
  const tab = searchParams.get('tab');
  if (tab) {
    const className = `.${tab}-sub-tabs`;
    const subNav = document.querySelector(className);
    if (subNav) {
      const anchorTags = subNav.querySelectorAll('a');
      let activeHref = null;
      anchorTags.forEach(element => {
        if (element.classList.contains('active')) {
          activeHref = element.getAttribute('href').replace('#', '');
          return;
        }
      });
      if (!activeHref && anchorTags.length > 0) {
        const firstAnchorTag = anchorTags[0];
        activeHref = firstAnchorTag.getAttribute('href').replace('#', '');
      }
      searchParams.set('sub_tab', activeHref);
    }
  }
  return searchParams;
}
