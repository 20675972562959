Uppy = require('@uppy/core')
Dashboard = require('@uppy/dashboard')
ActiveStorageUpload = require('uppy-activestorage-upload')


require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

(->
  class UploadUppyFile
    initialize: () ->
      document.addEventListener 'turbo:load', ->
          call_uppy()

      $(document).ready ->
        call_uppy() if $(".modal")[0]

    init_uppy: () ->
      call_uppy()
      return

    call_uppy = () ->
      document.querySelectorAll('[data-uppy]').forEach (element) ->
        setupUppy element

    setupUppy = (element) ->
      trigger = element.querySelector('[data-behavior="uppy-trigger"]')
      form = element.closest('form')
      direct_upload_url = document.querySelector('meta[name=\'direct-upload-url\']').getAttribute('content')
      allowed_file_types = $('#allowed_formats').val().split(', ')

      field_name = element.dataset.uppy

      trigger.addEventListener 'click', (event) ->
        event.preventDefault()
        return

      uppy = Uppy(
        autoProceed: true
        restrictions:
          maxFileSize: 8000000
          maxNumberOfFiles: 20
          minNumberOfFiles: 1
          allowedFileTypes: allowed_file_types
        requiredMetaFields: ['caption']
        allowMultipleUploads: true)

      uppy.use ActiveStorageUpload, directUploadUrl: direct_upload_url

      uppy.use Dashboard,
        trigger: trigger
        inline: true
        height: 150
        target: '#uppy_container'
        replaceTargetContent: true
        showProgressDetails: true
        hideProgressAfterFinish: true
        bundle: true
        metaFields: [
          { id: 'name', name: 'Name', placeholder: 'file name' },
        ]
        browserBackButtonClose: false


      uppy.on 'complete', (result) ->
        element.querySelectorAll('[data-pending-upload]').forEach (element) ->
          element.parentNode.removeChild element
          return
        result.successful.forEach (file) ->
          appendUploadedFile element, file, field_name
          return
        return
      return

    appendUploadedFile = (element, file, field_name) ->
      hiddenField = document.createElement('input')
      hiddenField.setAttribute 'type', 'hidden'
      hiddenField.setAttribute 'name', field_name
      hiddenField.setAttribute 'data-pending-upload', true
      hiddenField.setAttribute 'value', file.response.signed_id
      element.appendChild hiddenField
      return

  window.UploadUppyFile = new UploadUppyFile()
).call this
