(->
  class Employee
    Employee.update_url_path = (tab_name, employee_id = '') ->
      _path = set_params(tab_name, employee_id)
      history.pushState null, '', _path
      update_all_employees_link()

    set_params = (tab_name, employee_id) ->
      if 'URLSearchParams' of window
        searchParams = new URLSearchParams(window.location.search)
        searchParams.set('tab', tab_name)
        searchParams.set('employee_id', employee_id)
        newRelativePathQuery = "#{window.location.pathname}?#{searchParams.toString()}"
        return newRelativePathQuery

    update_all_employees_link = () ->
      $("#employees-list a").each (index, link) =>
        link.href = "#{link.href.split("=")[0]}=#{get_tab('tab')}"

    get_tab = (tab) ->
      searchParams = new URLSearchParams(window.location.search)
      return searchParams.get(tab)

    init: (employee_id) ->
      Employee.update_url_path(get_tab('tab'), employee_id)

      $('.employee-tabs').click ->
        Employee.update_url_path(this.dataset.tabName, get_tab('employee_id'))

      $('.employee-link').click ->
        Employee.update_url_path(get_tab('tab'), this.dataset.employeeId)

  window.Employee = new Employee()

).call this
