import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "totalAmount",
    "installments",
    "duration",
    "gap",
    "startDate",
    "installmentFields",
    "addPaymentOption",
  ];

  calculateInstallments() {
    const totalAmount = this.convertCurrencyToFloat(this.totalAmountTarget.value) || 0;
    const numberOfInstallments = parseInt(this.installmentsTarget.value, 10);
    const gap = parseInt(this.gapTarget.value);
    const duration = this.durationTarget.value;
    const startDate = new Date(this.startDateTarget.value);

    if (isNaN(totalAmount) || isNaN(numberOfInstallments) || isNaN(startDate)) {
      alert("Please enter valid inputs.");
      return;
    }

    const installmentAmount = Math.ceil((totalAmount / numberOfInstallments) * 100) / 100;
    let installmentDate = new Date(startDate);
    const installmentDates = [];

    for (let i = 0; i < numberOfInstallments; i++) {
      let dueDate;
      if (duration === "days") {
        dueDate = new Date(installmentDate);
        dueDate.setDate(installmentDate.getDate() + i * gap);
      } else if (duration === "weeks") {
        dueDate = new Date(installmentDate);
        dueDate.setDate(installmentDate.getDate() + i * 7 * gap);
      } else if (duration === "months") {
        dueDate = new Date(installmentDate);
        dueDate.setMonth(installmentDate.getMonth() + i * gap);
      }
      installmentDates.push({
        amount: installmentAmount,
        date: dueDate.toISOString().split("T")[0],
      });
    }

    return installmentDates
  }

  generateInstallments() {
    const installmentDates = this.calculateInstallments();
    const nestedFields = this.installmentFieldsTarget.querySelectorAll(".nested-fields");
    nestedFields.forEach((field) => {
      const removeButton = field.querySelector("a.remove_fields");
      if (removeButton) {
        removeButton.click();
      }
    });

    installmentDates.forEach((installment, index) => {
      const newField = document.createElement("div");
      newField.innerHTML = this.addPaymentOptionTarget.dataset.template
        .replace(/NEW_RECORD/g, new Date().getTime() + index)
        .replace(/INDEX/g, index)
        .replace(/AMOUNT_VALUE/g, installment.amount)
        .replace(/DATE_VALUE/g, installment.date);

      this.installmentFieldsTarget.appendChild(newField);
    });
  }

  updateInstallments() {
    const installmentDates = this.calculateInstallments();
    const nestedFields = this.installmentFieldsTarget.querySelectorAll(".nested-fields");

    nestedFields.forEach((field, index) => {
      const amountInput = field.querySelector(".amount-input");
      if (amountInput && installmentDates[index]) {
        amountInput.value = installmentDates[index].amount;
      }

      const dueDateInput = field.querySelector(".due-at-input");
      if (dueDateInput && installmentDates[index]) {
        dueDateInput.value = installmentDates[index].date;
      }
    });
  }

  convertCurrencyToFloat(value) {
    if (!value) return 0;
    return parseFloat(value.replace(/[^0-9.-]+/g, ""));
  }
}
