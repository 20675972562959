import { Controller } from "stimulus"
import Sortable, { MultiDrag } from 'sortablejs';

export default class extends Controller {
  static targets = ["blacklist", "whitelist"]

  async connect() {
    this.sortable_constructor(this.blacklistTarget)
    this.sortable_constructor(this.whitelistTarget)
  }

  sortable_constructor(element) {
    Sortable.create(element, {
      group: 'nested',
      animation: 100,
      fallbackOnBody: true,
      multiDrag: MultiDrag,
      selectedClass: "sortable-multi-selected",
      invertSwap: true,
      onAdd: (evt) => {
        const currentEl = element;
        let draggedItems = evt.to.querySelectorAll('.sortable-multi-selected');
        if (!draggedItems.length) {
          draggedItems = [evt.item];
        }

        draggedItems.forEach((itemEl) => {
          const dataId = itemEl.getAttribute('data-id');
          const id = currentEl.dataset.sortableTarget === "blacklist" ? '' : dataId
          $(itemEl).find("input").val(id)
        });
      }
    });
  }

}
