import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['editForm', 'amountText']

  toggle(event) {
    if(this.hasAmountTextTarget) {
      this.amountTextTarget.classList.toggle('d-none')
    }
    this.editFormTarget.classList.toggle('d-none')
  }
}