import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["adjustmentAmount", "amountOutstanding", "adjustmentType", "decisionType"];

  connect() {
    this.updateAdjustmentAmount();
    this.updateAdjustmentType();
  }

  updateAmountOutstanding(event) {
    this.amountOutstandingTarget.value = event.target.value;
    this.updateAdjustmentAmount();
  }

  updateAdjustmentAmount() {
    const amountOutstanding = parseFloat(this.amountOutstandingTarget.value) || 0;
    this.adjustmentAmountTarget.value = amountOutstanding
  }

  updateDecisionType(event) {
    this.decisionTypeTarget.value = event.target.value;
    this.updateAdjustmentType();
  }

  updateAdjustmentType() {
    const decisionType = this.decisionTypeTarget.value;
    let adjustmentType;

    switch(decisionType) {
      case 'provider_credit':
        adjustmentType = 'plus_provider_credit';
        break;
      case 'write_off_penalty':
        adjustmentType = 'minus_provider_penalty';
        break;
      default:
        adjustmentType = '';
    }


    this.adjustmentTypeTarget.value = adjustmentType;
  }
}
