import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "goal", "netGain", "forecast", "rewardPoint", "rewardConstraint",
    "totalPayout", "fullTimePrice", "halfTimePrice", "netGainPercentage",
    "fullTimeTotalPerson", "halfTimeTotalPerson", "fullTimePerPerson",
    "halfTimePerPerson", "fullTimeTotalPrice", "halfTimeTotalPrice", "forecastInput"
  ];

  static values = { defaultForecast: String }

  connect() {
    this.dispatch('updateStats', { message: 'working' })
    this.calculateAndUpdateValues(this.element.getAttribute('data-default-forecast-value'));
  }

  onChange(event) {
    this.calculateAndUpdateValues(parseFloat(event.currentTarget.value));
  }

  decrement(event) {
    this.adjustForecastValue(-parseFloat(this.element.getAttribute('data-increment-value')));
  }

  increment(event) {
    console.log(this.element.getAttribute('data-increment-value'))
    this.adjustForecastValue(parseFloat(this.element.getAttribute('data-increment-value')));
  }

  adjustForecastValue(delta) {
    const currentValue = parseFloat(this.forecastInputTarget.value);
    const minValue = parseFloat(this.forecastInputTarget.min);
    const maxValue = parseFloat(this.forecastInputTarget.max);

    const total = currentValue + delta

    console.table({currentValue, minValue, maxValue, total})

    if (total >= minValue && total <= maxValue) {
      this.calculateAndUpdateValues(currentValue + delta);
      this.forecastInputTarget.value = currentValue + delta
    }
  }

  calculateAndUpdateValues(forecast) {
    const [prefix, suffix] = this.getPrefixAndSuffix();
    const incrementValue = this.element.getAttribute('data-increment-value')

    const converter = window.converter;
    const goal = converter.convertCurrencyToFloat(this.goalTarget.textContent);
    const fullTimeTotalPerson = parseFloat(this.fullTimeTotalPersonTarget.textContent);
    const halfTimeTotalPerson = parseFloat(this.halfTimeTotalPersonTarget.textContent);
    const fullTimePrice = converter.convertCurrencyToFloat(this.fullTimePriceTarget.textContent);
    const halfTimePrice = converter.convertCurrencyToFloat(this.halfTimePriceTarget.textContent);

    const netGain = forecast - goal;
    const netGainPercentage = (netGain / goal) * 100;
    const increments = parseInt(netGain / incrementValue);
    const rewardPoint = increments


    const fullTimePerPersonPrice = fullTimePrice * increments;
    const halfTimePerPersonPrice = halfTimePrice * increments;
    const fullTimeTotalPrice = fullTimePerPersonPrice * fullTimeTotalPerson;
    const halfTimeTotalPrice = halfTimePerPersonPrice * halfTimeTotalPerson;
    const totalPayout = halfTimeTotalPrice + fullTimeTotalPrice;

    console.table({fullTimePerPersonPrice, halfTimePerPersonPrice, fullTimeTotalPrice, halfTimeTotalPrice, totalPayout})

    this.updateTargets(
      prefix,
      suffix,
      this.ensureNonNegative(netGain),
      this.ensureNonNegative(netGainPercentage),
      this.ensureNonNegative(rewardPoint),
      this.ensureNonNegative(fullTimePerPersonPrice),
      this.ensureNonNegative(halfTimePerPersonPrice),
      this.ensureNonNegative(fullTimeTotalPrice),
      this.ensureNonNegative(halfTimeTotalPrice),
      this.ensureNonNegative(totalPayout),
      this.ensureNonNegative(forecast)
    );

    this.updateStatsTable(
      this.ensureNonNegative(rewardPoint),
      this.ensureNonNegative(fullTimePerPersonPrice),
      this.ensureNonNegative(halfTimePerPersonPrice),
      this.ensureNonNegative(totalPayout)
    );
  }

  updateTargets(prefix, suffix, netGain, netGainPercentage, rewardPoint, fullTimePerPersonPrice, halfTimePerPersonPrice, fullTimeTotalPrice, halfTimeTotalPrice, totalPayout, forecast) {
    const numberToCurrencyFormatter = this.USdollarFormatter()

    this.forecastTarget.textContent = prefix == '$' ? numberToCurrencyFormatter.format(forecast) : `${prefix}${forecast}${suffix}`;
    this.netGainTarget.textContent = prefix == '$' ? numberToCurrencyFormatter.format(netGain) : `${prefix}${netGain}${suffix}`;
    this.netGainPercentageTarget.textContent = `${netGainPercentage > 0 ? "+" : "-"}${Math.abs(netGainPercentage).toFixed(2)}%`;
    this.netGainPercentageTarget.className = netGainPercentage > 0 ? "text-success" : "text-danger";
    this.rewardPointTarget.textContent = rewardPoint;
    this.fullTimePerPersonTarget.textContent = converter.convertFloatToCurrency(fullTimePerPersonPrice);
    this.halfTimePerPersonTarget.textContent = converter.convertFloatToCurrency(halfTimePerPersonPrice);
    this.fullTimeTotalPriceTarget.textContent = converter.convertFloatToCurrency(fullTimeTotalPrice);
    this.halfTimeTotalPriceTarget.textContent = converter.convertFloatToCurrency(halfTimeTotalPrice);
    this.totalPayoutTarget.textContent = converter.convertFloatToCurrency(totalPayout);
    this.forecastInputTarget.value = forecast
  }

  updateStatsTable(rewardPoint, fullTimePerPersonPrice, halfTimePerPersonPrice, totalPayout) {
    const numberToCurrencyFormatter = this.USdollarFormatter();
    const statsTableTarget = this.element.getAttribute('data-target');

    const table = document.querySelector(statsTableTarget);
    if (!table) {
      console.error(`Table not found for target: ${statsTableTarget}`);
      return;
    }

    const valueMap = {
      Point: rewardPoint,
      FullTime: numberToCurrencyFormatter.format(fullTimePerPersonPrice),
      HalfTime: numberToCurrencyFormatter.format(halfTimePerPersonPrice),
      TotalPayout: numberToCurrencyFormatter.format(totalPayout),
    };

    ['Point', 'FullTime', 'HalfTime', 'TotalPayout'].forEach(field => {
      const tagId = `${statsTableTarget}${field}`;
      const tag = table.querySelector(tagId);
      if (!tag) {
        console.warn(`Element not found for ID: ${tagId}`);
        return;
      }
      tag.textContent = valueMap[field];
    });
  }

  getPrefixAndSuffix() {
    let prefix = "", suffix = "";
    let forecastText = this.forecastTarget.textContent;

    if (forecastText.startsWith("$")) {
      prefix = "$";
    } else if (forecastText.endsWith("%")) {
      suffix = "%";
    }

    return [prefix, suffix];
  }

  USdollarFormatter() {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    });
  }

  ensureNonNegative(value) {
    return Math.max(0, value);
  }
}
