# payment_method = require('./stripe_payment_methods');
request = require '@rails/request.js'

(->
  class PaymentProcessor
    constructor: ->
      this.redirect_path = null
      this.client_secret = null
      this.has_saved_payment_methods = false

    initialize: (redirect_path, client_secret, has_saved_payment_methods) ->
      this.redirect_path = redirect_path
      this.client_secret = client_secret
      this.has_saved_payment_methods = has_saved_payment_methods

    pay_with_card: (card, stripe, controller_name) ->
      { has_saved_payment_methods, client_secret } = this
      StripeElements.loading true

      cardholderName = $("#name").value;
      data = {
        card: card,
        billing_details: {}
      };

      if cardholderName
        data["billing_details"]["name"] = cardholderName;

      if document.querySelector('.payment-radio')
        if document.querySelector('.payment-radio:checked') == null
          StripeElements.loading false;
          return

        card_choice = if has_saved_payment_methods == "true" then $('.payment-radio:checked').val() else 'new_card'

        if card_choice != 'new_card'
          data = getSavedCard(card_choice) # override card information if selected already saved card

      stripe.confirmCardPayment(client_secret, payment_method: data, setup_future_usage: 'off_session')
        .then (result) ->
          if result.error
            StripeElements.showError result.error.message
          else
            orderComplete(controller_name)
          return

    update_payment_method: (card, stripe, controller_name) ->
      cardholderName = document.querySelector("#name").value;

      data = {
        card: card,
        billing_details: {}
      };

      if cardholderName
        data["billing_details"]["name"] = cardholderName;

      stripe.createPaymentMethod(
        type: 'card'
        card: data["card"]
        billing_details: data["billing_details"]).
        then (result) ->
          if result.error
            StripeElements.showError result.error.message
          else
            $("#new_payment_method_id").val(result.paymentMethod.id)
            $('#update_payment_method').submit()
          return

    getSavedCard = (payment_method_id) ->
      return payment_method_id

    orderComplete = (controller_name) ->
      StripeElements.loading false;
      setTimeout ->
        redirect controller_name
      , 3000
      document.querySelector(".result-message").classList.remove("hidden");
      document.querySelector("button").disabled = true;
      $("#checkout :input").prop("disabled", true)

    redirect = (controller_name) ->
      switch controller_name
        when 'practices', 'onboardings'
         $.ajax
          url: "/registrations/#{controller_name}/steps/checkout"
          type: "GET"
          dataType: 'script'
        when 'financial_arrangements' then $("#billing_form").html('
          <div class="alert alert-success has-icon">
            <div class="alert-icon">
              <span class="oi oi-flag"></span>
            </div>
            <h4 class="alert-heading">Paid!</h4>
            <p>We will contact you shortly with next steps & scheduling info.</p>
          </div>
          ')
        else

  window.PaymentProcessor = new PaymentProcessor();

).call this
