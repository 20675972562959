import Sortable from 'sortablejs'
(->
  class SortableList
    SortableList.handle_response = () ->
      document.querySelectorAll('.positions').forEach (a) ->
        a.remove()
        return

      document.querySelectorAll('.trainings_payload').forEach (a) ->
        a.remove()
        return

      document.querySelectorAll("ul#training-chapters li").forEach (item, index) ->
        $('<input>').attr(
            type: 'hidden'
            value: item.dataset.id
            class: "trainings_payload"
            name: 'training_chapter[training_chapter_topics_attributes][' + index + '][training_topic_id]'
          ).appendTo 'form'

        $('<input>').attr(
            type: 'hidden'
            class: "positions"
            value: index
            name: 'training_chapter[training_chapter_topics_attributes][' + index + '][position]'
          ).appendTo 'form'

    initialize: (initial_data = false) ->
      training_series = document.getElementById('training-chapters');
      training_topics = document.getElementById('training-topics');

      Sortable.create training_series,
        sort: true
        animation: 100
        group: 'nested'
        multiDrag: true
        selectedClass: 'active'

        onAdd: (evt) ->
          SortableList.handle_response()

        onEnd: (evt) ->
          SortableList.handle_response()

      Sortable.create training_topics,
        sort: true
        animation: 100
        group: 'nested'
        multiDrag: true
        selectedClass: 'active'

      if (initial_data)
        SortableList.handle_response()


  window.SortableList = new SortableList()

).call this
