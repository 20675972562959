import { Controller } from "stimulus"

function updateTurboFrame(turbo_frame, src) {
  turbo_frame.innerHTML = Shared.get_loader();
  turbo_frame.setAttribute("src", null);
  turbo_frame.setAttribute("src", src);
}

function getSearchParams(url) {
  const searchParams = new URLSearchParams(url.split('?')[1]);
  const tab = searchParams.get('tab');
  if (tab) {
    const className = `.${tab}-sub-tabs`;
    const subNav = document.querySelector(className);
    if (subNav) {
      const anchorTags = subNav.querySelectorAll('a');
      let activeHref = null;
      anchorTags.forEach(element => {
        if (element.classList.contains('active')) {
          activeHref = element.getAttribute('href').replace('#', '');
          return; // Exit the loop once the active element is found
        }
      });
      if (!activeHref && anchorTags.length > 0) {
        const firstAnchorTag = anchorTags[0];
        activeHref = firstAnchorTag.getAttribute('href').replace('#', '');
      }
      searchParams.set('sub_tab', activeHref);
    }
  }
  return searchParams;
}
export default class extends Controller {
  static targets = ["dropDownFilter", "calendarPicker"]

  connect() {
    if ($(this.element).data('viewType')) {
      const pickerValue = $(this.element).data('viewType')
      const datepickerInput = this.element.querySelector(`input[data-behavior="flatpickr_${pickerValue}"]`);
      const datePickerID = $(datepickerInput).attr('id')
      eval(`datetime_picker.${pickerValue}_input('${datePickerID}');`);
    }
    Charts.initialize();
  }

  changeEntityPicker(e) {
    if ($(this.element).parents('turbo-frame[loading="lazy"]').length < 1)
      return null;

    const turbo_frame = $(this.element).parents('turbo-frame[loading="lazy"]')[0];
    let src = $(turbo_frame).attr('src');

    $(e.currentTarget).find('input[type="radio"][name="entityFilter"]').attr('checked', true);

    const entityable = $(this.element).find('input[type="radio"][name="entityFilter"]:checked').val();
    if (entityable) {
      const [entityable_id, entityable_type] = entityable.split('-');

      const searchParams = getSearchParams(src);
      searchParams.set('entityable_id', entityable_id);
      searchParams.set('entityable_type', entityable_type);

      src = src.split('?')[0] + '?' + searchParams.toString();
    }

    updateTurboFrame(turbo_frame, src);
  }

  changeCalendarPicker(e) {
    if ($(this.element).parents('turbo-frame[loading="lazy"]').length < 1)
      return null;

    const turbo_frame = $(this.element).parents('turbo-frame[loading="lazy"]')[0];
    let src = $(turbo_frame).attr('src');

    const date = e.currentTarget.value;
    if (date) {
      const searchParams = getSearchParams(src);
      searchParams.set('date', date);

      src = src.split('?')[0] + '?' + searchParams.toString();
    }

    updateTurboFrame(turbo_frame, src);
  }

  changeDateNavigator(e) {
    if ($(this.element).parents('turbo-frame[loading="lazy"]').length < 1)
      return null;

    const turbo_frame = $(this.element).parents('turbo-frame[loading="lazy"]')[0];
    let src = $(turbo_frame).attr('src');
    const date = $(e.currentTarget).find('input[type="radio"]').val();

    if (date) {
      const searchParams = getSearchParams(src);
      searchParams.set('date', date);
      src = src.split('?')[0] + '?' + searchParams.toString();
    }

    updateTurboFrame(turbo_frame, src);
  }

  changeDateType(e) {
    if ($(this.element).parents('turbo-frame[loading="lazy"]').length < 1)
      return null;

    const turbo_frame = $(this.element).parents('turbo-frame[loading="lazy"]')[0];
    let src = $(turbo_frame).attr('src');
    const date_type = $(e.currentTarget).find('input[type="radio"]').val();

    if (date_type) {
      const searchParams = getSearchParams(src);
      searchParams.set('date_type', date_type);
      searchParams.delete('entityable_id');
      searchParams.delete('entityable_type');
      src = src.split('?')[0] + '?' + searchParams.toString();
    }

    updateTurboFrame(turbo_frame, src);
  }

  closeDropdown(e) {
    if (e && this.dropDownFilterTarget.contains(e.target)) {
      return
    }
    if (this.dropDownFilterTarget.classList.contains('show')) {
      $(this.dropDownFilterTarget).removeClass('show');

      if ($(this.element).parents('turbo-frame[loading="lazy"]').length < 1)
        return null;

      let turbo_fame = $(this.element).parents('turbo-frame[loading="lazy"]')[0];
      let src = $(turbo_fame).attr('src');

      var searchParams = new URLSearchParams(src.split('?')[1])
      searchParams.delete('dropdown_status')

      src = src.split('?')[0] + '?' + searchParams.toString()
      $(turbo_fame).attr('disabled', true)
      $(turbo_fame).attr('src', src);
      $(turbo_fame).attr('disabled', false)
    }
  }
}
