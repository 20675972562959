import { Controller } from "stimulus"

// Connects to data-controller="aftercare-log"
export default class extends Controller {
  static targets = ["btn", "output"]

  static values = {
    id: String,
    caseTrackingType: String
  }

  resetStats() {
    let pending = $("#stats_filters").find('.pending')
    let completed = $("#stats_filters").find('.completed')

    pending.text((_ ,text) => parseInt(text) - 1)
    completed.text((_ ,text) => parseInt(text) + 1)
  }

  addCaseTracking(event) {

    this.caseTrackingTypeValue = event.target.dataset.caseTrackingType.replace(/_/g, " ")

    this.btnTarget.classList.remove("d-none")
    $("#new_case_tracking_card").removeClass("d-none")

    if($(this.btnTarget).siblings("h6").length > 0)
    {
      $(this.btnTarget).siblings("h6").remove()
    }

    $("<h6> New " + this.caseTrackingTypeValue + " </h6>").insertAfter(this.btnTarget);

    if (this.outputTargets.filter(t=> t.hasChildNodes()).length > 0){
      this.outputTargets.find(t=> t.hasChildNodes()).innerHTML = ""
    }
  }

  removeCaseTracking() {
    this.btnTarget.classList.add("d-none")
    $("#new_case_tracking_card").addClass("d-none")

    if($(this.btnTarget).siblings("h6").length > 0)
    {
      $(this.btnTarget).siblings("h6").remove()
    }

    this.outputTargets.find(t=> t.hasChildNodes()).innerHTML = ""
  }

  toggle() {

    if(this.element.checked){
      $('#'+this.idValue).val(false);
    }
    else {
      $('#'+this.idValue).val(true);
    }
  }

}
