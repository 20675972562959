import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    redirectToUrl: String,
  }

  connect() {
    if (!this.hasRedirectToUrlValue)
      return null;

    this.redirectPageTo(this.redirectToUrlValue)
  }

  redirectPageTo(redirectToUrl) {
    window.location.href = redirectToUrl
  }
}
