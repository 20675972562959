import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submit", "selectedCount", "totalAmount"];

  connect() {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    const checkedCheckBoxes = this.element.querySelectorAll('input[type="checkbox"]:checked');
    const totalChecked = checkedCheckBoxes.length
    this.submitTarget.disabled = totalChecked <= 0;
    this.updateCounter(totalChecked);
    this.updateTotalAmount(checkedCheckBoxes)
  }

  updateCounter(totalChecked) {
    this.selectedCountTarget.textContent = totalChecked;
  }

  updateTotalAmount(checkedCheckBoxes) {
    let total = 0;

    checkedCheckBoxes.forEach((checkbox) => {
      let amount = parseFloat(checkbox.dataset.amount) || 0;
      total += amount
    })

    this.totalAmountTarget.textContent = total.toFixed(2)
  }
}