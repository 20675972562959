(->
  class HygAftercareLog
    initialize: ->
      $("#appointments-list").change ->
        appointment_at = $('#appointments-list input:checked')?.data('appointment-at')?.split('"')[1]
        d = new Date(appointment_at)
        id = if $('#appointments-list input:checked') then $('#appointments-list input:checked')[0]?.value else null

        $('#hyg_aftercare_log_future_appointment_id').val(id)
        if (id)
          $("#hyg_aftercare_log_notes").removeAttr('required')
          $("#hyg_aftercare_log_notes").addClass('d-none')
        else
          $("#hyg_aftercare_log_notes").addAttr('required')
          $("#hyg_aftercare_log_notes").removeClass('d-none')

        $("#indicators").removeClass('d-none')
        $.ajax
          type: 'GET'
          dataType: 'json'
          data: { type: 'Appointment' }
          url: "/primetimes/" + id + "/primetime_status"
          success: (data) ->
            if !data
              $('#red').removeClass 'off-led-red'
              $('#red').addClass 'led-red'
              $('#green').removeClass 'led-green'
              $('#green').addClass 'off-led-green'
            else
              $('#red').removeClass 'led-red'
              $('#red').addClass 'off-led-red'
              $('#green').removeClass 'off-led-green'
              $('#green').addClass 'led-green'
            return

      has_perio = $('#has_perio_switcher').prop('checked')
      if has_perio
        $("#perio_fields").show()

      $("#has_perio_switcher").change ->
        $("#perio_fields").toggle()
        if $('#has_perio_switcher').prop('checked')
          $('#perio_fields :input').prop('disabled', false)
        else
          $('#perio_fields :input').prop('disabled', true)


      update_perio_case  = (perio_case_id) ->
        if $('.selected_case :selected').text() == 'Add New' || $('.selected_case :selected').val() == ''
          $("#perio_case_partial").addClass('d-none')
        else
          $("#perio_case_partial").removeClass('d-none')
          $.ajax
            type: 'GET'
            url: "/perio-cases/#{perio_case_id}/show_timeline"
            dataType: 'html'
            success: (result) ->
              $('#perio_case_partial').html(result);

      $('.selected_case').change ->
        update_perio_case(this.value)


  window.HygAftercareLog = new HygAftercareLog()

).call this
