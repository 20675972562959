import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["turboFrame"]

  connect() {
  }

  change(event) {
    const selectedValue = event.target.value
    this.updatePaymentDescription(selectedValue)
    this.updateTurboFrame(selectedValue)
  }

  updateTurboFrame(value) {
    const turboFrame = this.turboFrameTarget
    const url = turboFrame.src
    turboFrame.innerHTML = Shared.get_loading();
    const searchParams = new URLSearchParams(url.split('?')[1]);
    searchParams.set('payment_schedule_type', value)
    turboFrame.src = url.split('?')[0] + '?' + searchParams.toString();
  }

  updatePaymentDescription(value) {
    $(this.element).find("input[type='radio']").each(function (index, element) {
      if (element.value == value) {
        $(`#${element.value}_container`).removeClass('d-none')
      } else {
        $(`#${element.value}_container`).addClass('d-none')
      }
    })
  }
}
