(->
  class User

    fetch_users_by_email: (enabled = true) ->
      $input = $('#user-email-search')

      options =
          url: (phrase) ->
              '/users/search?q=' + phrase

          placeholder: "Enter Email Address"
          getValue: 'email'
          template:
              type: 'custom'
              method: (value, item) ->
                return '<p id=' + item.id + '> '+ item.email + '</p>'
          requestDelay: 500
          list:
            showAnimation:
              type: "slide"
            hideAnimation:
              type: "slide"

            onShowListEvent: ->
             $("#search-loader").addClass('show')

            onHideListEvent: ->
             $("#search-loader").removeClass('show')

            onSelectItemEvent: ->
              item = $input.getSelectedItemData()
              if item.email != $input.val()
                return

              $("#employee_user_attributes_phone").val(item.phone).trigger("change")

              $("#user-id").prop("disabled", false)
              $("#employee_user_attributes_id").val(item.id).trigger("change")
              $("#employee_user_attributes_phone").prop("disabled", true)
              $("#new_user_email").prop("disabled", true)

              $("#user-id").val(item.id).trigger("change")
              $("#employee_first_name").val(item.first_name).trigger("change")
              $("#employee_last_name").val(item.last_name).trigger("change")
              $("#user_selection_type").val("existing_user").trigger("change")


      if enabled
        # $input.easyAutocomplete options
        $input.keyup (e) ->
          emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
          isInvalid = not emailRegex.test($input.val())
          $input.toggleClass('is-invalid', isInvalid)


          if $input.val() == $("#new_user_email").val()
            return
          $("#user-id").prop("disabled", false)
          $("#employee_user_attributes_phone").prop("disabled", false)
          $("#new_user_email").prop("disabled", false)
          $("#new_user_email").val($input.val())

          $("#user_selection_type").val("new_user").trigger("change")
          $input.removeClass('is-valid')
          $("#user-badge").addClass('d-none')

          email = $input.val()
          $.ajax
            url: '/users/search'
            data: { q: email }
            dataType: 'json'
            success: (user_emails) ->
              item = user_emails.find((user) -> user.email == $input.val())
              if item
                $("#employee_user_attributes_phone").val(item.phone).trigger("change")

                $("#user-id").prop("disabled", false)
                $("#employee_user_attributes_id").val(item.id).trigger("change")
                $("#employee_user_attributes_phone").prop("disabled", true)
                $("#new_user_email").prop("disabled", true)

                $("#user-id").val(item.id).trigger("change")
                $("#employee_first_name").val(item.first_name).trigger("change")
                $("#employee_last_name").val(item.last_name).trigger("change")
                $("#user_selection_type").val("existing_user").trigger("change")
                $input.addClass('is-valid')
                $("#user-badge").removeClass('d-none')


    onboarding_control: ->
      $("#onboarding-control").change ->
        if this.checked
          $("#employees_form").find(".steps").removeClass("d-none")
        else
          $("#employees_form").find(".steps").addClass("d-none")


    init: ->

      requestData = (request) ->
        href = $(request).data('href')
        params = {}
        params["q"] = { assigned_to_id_eq: $(request).data('user') } if href == "/feedbacks"
        params["q"] = { user_id_eq: $(request).data('user') } if href == "/absent_days"
        $.ajax
          type: 'GET'
          url: href
          data: params
          dataType: 'script'
          success: () ->
            return
        return

      $('#admin-user-tab a[data-toggle="tab"]').on 'shown.bs.tab', (e) ->
        requestData(this)

  window.User = new User()

).call this
