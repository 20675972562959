import { Controller } from "stimulus"
import Sortable, { MultiDrag } from 'sortablejs';

export default class extends Controller {
  static targets = ["blacklist", "whitelist"]

  async connect() {
    this.sortable_constructor(this.blacklistTarget)
    this.sortable_constructor(this.whitelistTarget)
  }

  sortable_constructor(element) {
    Sortable.create(element, {
      group: 'nested',
      animation: 100,
      fallbackOnBody: true,
      multiDrag: MultiDrag,
      selectedClass: "sortable-multi-selected",
      invertSwap: true,
      onAdd: (evt) => {
        const currentEl = element;
        let draggedItems = evt.to.querySelectorAll('.sortable-multi-selected');
        if (!draggedItems.length) {
          draggedItems = [evt.item];
        }

        draggedItems.forEach(async (itemEl) => {
          const dataId = itemEl.getAttribute('data-id');
          const subscribable = itemEl.getAttribute('data-subscribable');
          const subscribableId = itemEl.getAttribute('data-subscribable-id');
          const requestMethod = currentEl.dataset.subscriberTarget === "blacklist" ? 'DELETE' : 'POST';
          const url = `/${toPlural(subscribable)}/${subscribableId}/subscriptions`;

          try {
            const response = await $.ajax({
              type: requestMethod,
              dataType: "script",
              url: requestMethod === 'DELETE' ? `/subscriptions/${dataId}` : url,
              data: {
                model_name: subscribable,
                [`${subscribable}_id`]: subscribableId,
                id: subscribableId,
                subscription: { employee_id: dataId }
              }
            });

            await $.ajax({
              type: "GET",
              dataType: "script",
              url: "/subscriptions/subscribable_state",
              data: {
                model_name: subscribable,
                [`${subscribable}_id`]: subscribableId,
              }
            });
          } catch (error) {
            console.error(error);
          }
        });
      }
    });
  }

}

function toPlural(word) {
  const specialPlurals = {
    'person': 'people',
    'man': 'men',
    'child': 'children',
    'tooth': 'teeth',
    'foot': 'feet',
    'mouse': 'mice',
    'goose': 'geese'
  };

  if (specialPlurals[word]) {
    return specialPlurals[word];
  }

  const irregularEndings = [
    { ending: 'is', replacement: 'es' },
    { ending: 'us', replacement: 'i' },
    { ending: 'on', replacement: 'a' }
  ];

  for (let i = 0; i < irregularEndings.length; i++) {
    const { ending, replacement } = irregularEndings[i];
    if (word.endsWith(ending)) {
      return word.slice(0, -ending.length) + replacement;
    }
  }

  if (word.endsWith('y') && !/[aeiou]y/.test(word)) {
    return word.slice(0, -1) + 'ies';
  } else if (word.endsWith('s') || word.endsWith('sh') || word.endsWith('ch') || word.endsWith('x') || word.endsWith('z')) {
    return word + 'es';
  } else {
    return word + 's';
  }
}
