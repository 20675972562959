
(->
  window.Goals or  (window.Goals = {})

  Goals.loadStats = (id) ->
    $.ajax
      url: "/goals/#{id}/stats"
      type: "GET"
      dataType: "script"
      success: (data) ->
        $(".goals-stats-container").html(data)

  Goals.initializeSelect = ->
    $ ->
      $('#subscriptions').on 'cocoon:after-insert', (e, inserted_item) ->
        $('.user_id').select2()
        return

  Goals.populateFusionCharts = (dataSource, alwaysLoad = true) ->
    FusionCharts.ready ->
      shouldLoad = $("##{dataSource.renderAt}")?.html()?.trim() == ''

      if (alwaysLoad || shouldLoad)
        new FusionCharts(dataSource).render();

).call this
