( ->
  class InsuranceClaim
    init: ->
      $("[name*='requires_preauth']").change ->
        if this.value == 'true'
          $('#insurance_claim_fields').removeClass('d-none')
          $("#insurance_claim_fields :input").prop('required', true)
        else
          $('#insurance_claim_fields').addClass('d-none')
          $("#insurance_claim_fields :input").prop('disabled', true)


  window.InsuranceClaim = new InsuranceClaim();
).call this
