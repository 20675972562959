(->
  class Payroll
    initialize: ->
      show_standard_payroll = ->
        $("#standard").removeClass('d-none');
        $('#standard :input').prop('disabled', false)
        $("#monthly-bonus").addClass('d-none');
        $('#monthly-bonus :input').prop('disabled', true)
        $('#q_payroll_type').val('standard')

      show_monthly_bonus_payroll = ->
        $("#monthly-bonus").removeClass('d-none');
        $('#monthly-bonus :input').prop('disabled', false)
        $("#standard").addClass('d-none');
        $('#standard :input').prop('disabled', true)
        $('#q_payroll_type').val('monthly_bonus')

      if $('#standard').hasClass('show')
        show_standard_payroll()
      else if $('#monthly-bonus').hasClass('show')
        show_monthly_bonus_payroll()

      $('#date_tabs a[data-toggle="tab"]').on 'shown.bs.tab', (e) ->
        if $('#standard').hasClass('show')
          show_standard_payroll()
        else if $('#monthly-bonus').hasClass('show')
          show_monthly_bonus_payroll()

      $('#q_view_type_group').change ->
        $('#team-members-container').addClass 'd-none'
        $('#roles-container').removeClass 'd-none'
        $('#q_user_id_eq').prop('disabled', true)
        $('#q_user_id_eq').prop('required', false)
        $('#q_role_in').prop('disabled', false)

      $('#q_view_type_individual').change ->
        $('#team-members-container').removeClass 'd-none'
        $('#roles-container').addClass 'd-none'
        $('#q_user_id_eq').prop('disabled', false)
        $('#q_user_id_eq').prop('required', true)
        $('#q_role_in').prop('disabled', true)

    manageStats: ->
      doctors_standard_pay = $('#doctors_standard_pay').text()
      $('#doctors_standard_pay_value').html("<em>#{doctors_standard_pay}</em>")
      hygenists_standard_pay = $('#hygenists_standard_pay').text()
      assistants_standard_pay = $('#assistants_standard_pay').text()
      $('#assistants_standard_pay_value').html("<em>#{assistants_standard_pay}</em>")
      front_desks_standard_pay = $('#front_desks_standard_pay').text()
      $('#front_desks_standard_pay_value').html("<em>#{front_desks_standard_pay}</em>")
      specialists_standard_pay = $('#specialists_standard_pay').text()
      $('#specialists_standard_pay_value').html("<em>#{specialists_standard_pay}</em>")

      doctors_bonus = $('#doctors_bonus').text()
      $('#doctors_bonus_value').html("<em>#{doctors_bonus}</em>")
      hygenists_bonus = $('#hygenists_bonus').text()
      $('#hygenists_bonus_value').html("<em>#{hygenists_bonus}</em>")
      $('#assistants_bonus_value').html("")
      $('#front_desks_bonus_value').html("")
      $('#specialists_bonus_value').html("")

      doctors_standard_pay = Number(doctors_standard_pay.replace(/[^0-9.-]+/g,""))
      hygenists_standard_pay = Number(hygenists_standard_pay.replace(/[^0-9.-]+/g,""))
      assistants_standard_pay = Number(assistants_standard_pay.replace(/[^0-9.-]+/g,""))
      front_desks_standard_pay = Number(front_desks_standard_pay.replace(/[^0-9.-]+/g,""))
      specialists_standard_pay = Number(specialists_standard_pay.replace(/[^0-9.-]+/g,""))
      doctors_bonus = Number(doctors_bonus.replace(/[^0-9.-]+/g,""))
      hygenists_bonus = Number(hygenists_bonus.replace(/[^0-9.-]+/g,""))

      formatter = new (Intl.NumberFormat)('en-US',
        style: 'currency'
        currency: 'USD'
        minimumFractionDigits: 2)

      hygenists_total = hygenists_standard_pay
      hygenists_standard_pay = hygenists_standard_pay - hygenists_bonus
      doctors_total = doctors_standard_pay + doctors_bonus
      front_desks_total = front_desks_standard_pay + 0
      assistants_total = assistants_standard_pay + 0
      specialists_total = specialists_standard_pay + 0

      grand_total = hygenists_total + doctors_total + front_desks_total + assistants_total + specialists_total

      $('#hygenists_standard_pay_value').html("<em>#{formatter.format(hygenists_standard_pay)}</em>")

      $('#doctors_total_value').html("<em>#{formatter.format(doctors_total)} <b>(#{((doctors_total / grand_total) * 100).toFixed(2)}%)</b></em>")
      $('#hygenists_total_value').html("<em>#{formatter.format(hygenists_total)} <b>(#{((hygenists_total / grand_total) * 100).toFixed(2)}%)</b></em>")
      $('#front_desks_total_value').html("<em>#{formatter.format(front_desks_total)} <b>(#{((front_desks_total / grand_total) * 100).toFixed(2)}%)</b></em>")
      $('#assistants_total_value').html("<em>#{formatter.format(assistants_total)} <b>(#{((assistants_total / grand_total) * 100).toFixed(2)}%)</b></em>")
      $('#specialists_total_value').html("<em>#{formatter.format(specialists_total)} <b>(#{((specialists_total / grand_total) * 100).toFixed(2)}%)</b></em>")

      $('#grand_standard_pay').html("<em>#{formatter.format(doctors_standard_pay + hygenists_standard_pay + front_desks_standard_pay + assistants_standard_pay + specialists_standard_pay)}</em>")
      $('#grand_bonus').html("<em>#{formatter.format(doctors_bonus + hygenists_bonus)}</em>")
      $('#grand_total').html("<em>#{formatter.format(grand_total)}</em>")

    getStats: ->
      _that = this
      $(document).ready ->
        _that.manageStats()

  window.Payroll = new Payroll()

).call this
