(->
  window.BillingIssue or  (window.BillingIssue = {})

  BillingIssue.init = ->
    $ ->
      $('#add-patient-manual').click ->
        $("#patient-new-form, #add-patient-dropdown").removeClass('d-none');
        $("#patients-dropdown, #add-patient-manual").addClass('d-none');

      $('#add-patient-dropdown').click ->
        $("#patients-dropdown, #add-patient-manual").removeClass('d-none');
        $("#add-patient-dropdown, #patient-new-form").addClass('d-none');


      $('#narrative_authorship_type').change ->
        if this.value == "request_from_provider"
          $("#insurance_claim_narrative").val('');
          $("#insurance_claim_narrative").prop('required', false)
          $("#narrative_fields").addClass('d-none');
          $("#narrative-info").removeClass('d-none');
        else if this.value == "extract_from_notes"
          $("#insurance_claim_narrative").prop('required', true)
          $("#narrative_fields").removeClass('d-none');
          $("#narrative-info").addClass('d-none');
        else if this.value == "unnecessary"
          $("#insurance_claim_narrative").val('');
          $("#insurance_claim_narrative").prop('required', false)
          $("#narrative_fields").addClass('d-none');
          $("#narrative-info").addClass('d-none');

).call this
