(->
    class Shared
        tootltip_initializer: ->
          $('[data-toggle="tooltip"]').tooltip()

          $(document).on 'click', (event) ->
            $('[data-toggle="tooltip"]').tooltip("hide")

          $(document).ajaxComplete ->
            $('[data-toggle="tooltip"]').tooltip()

          $(document).on 'turbo:frame-render', (event) ->
            $('[data-toggle="tooltip"]').tooltip()

          $(document).on 'futurism:appeared', (event) ->
            $('[data-toggle="tooltip"]').tooltip()

        popover_initializer: ->
          $.fn.popover.Constructor.Default.whiteList["turbo-frame"] = ['src', 'loading']

          $('[data-toggle="popover"]').popover()

          $(document).ajaxComplete ->
            $('[data-toggle="popover"]').popover()

          $(document).on 'turbo:frame-render', (event) ->
            $('[data-toggle="popover"]').popover()

          $(document).on 'futurism:appeared', (event) ->
            $('[data-toggle="popover"]').popover()

        get_loader: ->
          return '<div class="spinner-grow text-info" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                          </div>'
        get_loading: ( loadingText = 'loading') ->
          return "<div class='w-100 text-center pulse align-middle ' style='height: 25px;'>#{loadingText} ...</div>"

        get_loading_bar: ( loadingText = 'loading') ->
          return "<div class='progress-bar progress-bar-striped progress-bar-animated' role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100' style='width: 100%'>#{loadingText}</div>"

        init_modal_form_remotely: ->
          $('.modal form').attr 'data-remote', true

          $('.modal-body').on 'DOMSubtreeModified', ->
            $('.modal form').attr 'data-remote', true
            return

        modal_detector: ->
          $.ajaxSetup
            beforeSend: ->
              if $('#mainModalDrawer').is(':visible') or $('#mainModal').is(':visible')
                this.url += "#{(if this.url.includes('?') then '&' else '?')}modal_request=true"

        push_param_to_url: (src, key) ->
          windowSearchParams = new URLSearchParams(window.location.search)
          if windowSearchParams.has(key)
            searchParams = new URLSearchParams(src.split('?')[1])
            searchParams.set key, windowSearchParams.get(key)
            src = src.split('?')[0] + '?' + searchParams.toString()
          return src

        set_params: (tab, value) ->
          if 'URLSearchParams' of window
            searchParams = new URLSearchParams(window.location.search)
            searchParams.set tab, value
            newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
            return newRelativePathQuery


    window.Shared = new Shared()

).call this
