( ->
  class OrthoCase
    init: ->
      $("[name*='case_materials_sent_at']").change ->
        if this.value == ''
          $("#ortho_case_case_materials_sent_by_id").prop('disabled', true)
        else
          $("#ortho_case_case_materials_sent_by_id").prop('required', true)

      $('#ortho_descision_type').change ->
        if this.value == "Accept"
          $('#payment_option_fields').removeClass "d-none"
          $('#payment_option_fields input').prop('required', true)
        else
          $('#payment_option_fields').addClass "d-none"
          $('#payment_option_fields input').prop('required', false)

      $('#ortho_payment_option').change ->
        if this.value == "financing_by_care_credit"
          $('.care-credit-form').removeClass "d-none"
          $('.care-credit-fields').each (i, obj) ->
            $(obj).prop('required', true)
            $(obj).val('')
        else
          $('.care-credit-form').addClass "d-none"
          $('.care-credit-fields').each (i, obj) ->
            $(obj).prop('required', false)


  window.OrthoCase = new OrthoCase();
).call this
