( ->
  class ReferralCase
    init: ->
      $('#referral_case_patient_decision_type').change ->
        if this.value == "Accept"
          $('#payment_option_fields').removeClass "d-none"
          $('#payment_option_fields input').prop('required', true)
        else
          $('#payment_option_fields').addClass "d-none"
          $('#payment_option_fields input').prop('required', false)

      $('#referral_case_payment_option').change ->
        if this.value == "financing_by_care_credit"
          $('.care-credit-form').removeClass "d-none"
          $('.care-credit-fields').each (i, obj) ->
            $(obj).prop('required', true)
            $(obj).val('')
        else
          $('.care-credit-form').addClass "d-none"
          $('.care-credit-fields').each (i, obj) ->
            $(obj).prop('required', false)


  window.ReferralCase = new ReferralCase();
).call this
