import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["element", "element01", "select"]

  connect() {
    let element01 = this.element01Target;
    element01.hidden = true;
  }

  changed(event) {
    if (this.hasElementTarget) {
      console.log("changed")
      this.toggle(this.elementTarget, this.elementTarget.dataset.values, event.target);
    }
  }

  toggle(element, values, target) {
    let element01 = this.element01Target;
    console.log(element)
    if (element && target.value) {
      let hidden = true;
      for (let _value of values.split(",")) {
        if (_value === target.value) {
          hidden = false;
        }
      }
      if (target.value === values){
        element01.hidden = true;
      }
      else{
        element01.hidden = false;
      }
      element.hidden = hidden;
    }
  }

}
