(->
  window.AfterCare or  (window.AfterCare = {})

  AfterCare.init = ->
    $ ->
      $('#clinical_aftercare_log_endo, #clinical_aftercare_log_filling, #clinical_aftercare_log_crown, #clinical_aftercare_log_removable').change ->
        if $(this).prop("checked")
          $("##{$(this).attr('id')}_procedure").removeClass('d-none');
          $("##{$(this).attr('id')}_validate").get(0).value = 'true';
        else
          $("##{$(this).attr('id')}_procedure").addClass('d-none');
          $("##{$(this).attr('id')}_validate").get(0).value = 'false'; 

).call this
