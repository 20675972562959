import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["template", "container"]

  connect() {
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.containerTarget.insertAdjacentHTML('beforeend', content);
    $('.select2').select2()
  }

  addField(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.containerTarget.insertAdjacentHTML('beforeend', content);
    $('.select2').select2()
  }

  removeField(event) {
    event.preventDefault();
    event.target.closest('.nested-field').remove();
  }
}
