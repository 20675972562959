import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['eachFullTimePayout', 'totalFullTimePayout', 'eachPartTimePayout', 'totalPartTimePayout', 'perEmployeeTotal', 'totalPayout']

  connect() {
    const incrementAchievedTotal = this.sumDataValuesByGoalType('.OfficeRewardGoal_increment_achieved')

    const perFullTimeTotal = this.sumDataValuesByGoalType('.OfficeRewardGoal_per_full_time_total')
    const fullTImeTotal = this.sumDataValuesByGoalType('.OfficeRewardGoal_full_time_total')

    const perPartTimeTotal = this.sumDataValuesByGoalType('.OfficeRewardGoal_per_part_time_total')
    const partTImeTotal = this.sumDataValuesByGoalType('.OfficeRewardGoal_part_time_total')

    const perEmployeeTotal = perFullTimeTotal.total + perPartTimeTotal.total
    const totalPayout = fullTImeTotal.total + partTImeTotal.total

    this.eachFullTimePayoutTarget.textContent = this.formatCurrency(perFullTimeTotal.total)
    this.totalFullTimePayoutTarget.textContent = this.formatCurrency(fullTImeTotal.total)
    this.eachPartTimePayoutTarget.textContent = this.formatCurrency(perPartTimeTotal.total)
    this.totalPartTimePayoutTarget.textContent = this.formatCurrency(partTImeTotal.total)

    this.perEmployeeTotalTarget.textContent = this.formatCurrency(perEmployeeTotal)
    this.totalPayoutTarget.textContent = this.formatCurrency(totalPayout)

    this.updateTextContentById(Object.entries(incrementAchievedTotal), 'Point');
    this.updateTextContentById(Object.entries(fullTImeTotal), 'FullTime', this.formatCurrency);
    this.updateTextContentById(Object.entries(partTImeTotal), 'HalfTime', this.formatCurrency);
  }

  sumDataValues(selector) {
    const elements = document.querySelectorAll(selector);

    let sum = 0;
    elements.forEach(element => {
        const value = element.getAttribute('data-value');
        sum += Number(value);
    });

    return sum;
  }

  sumDataValuesByGoalType(selector) {
    const elements = document.querySelectorAll(selector);

    const sumsByGoalType = { total: 0 };

    elements.forEach(element => {
      const value = Number(element.getAttribute('data-value'));
      const goalType = element.getAttribute('data-goal-type');

      if (!sumsByGoalType[goalType]) {
        sumsByGoalType[goalType] = 0;
      }

      sumsByGoalType[goalType] += value;
      sumsByGoalType.total += value;
    });
    return sumsByGoalType;
  }

  formatCurrency(value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  }

  updateTextContentById = (entries, idSuffix, formatter = (v) => v) => {
    entries.forEach(([key, value]) => {
      const target = document.getElementById(`${key}${idSuffix}`)
      if (target) target.textContent = formatter(value)

      if(idSuffix == 'FullTime' || idSuffix == 'HalfTime') {
        const totalPayoutKey = `${key}TotalPayout`
        const totalPayout = document.getElementById(totalPayoutKey)

        if(totalPayout) {
          let totals = parseFloat(totalPayout.textContent.replace(/[^0-9.-]+/g, ""));
          totals += value
          totalPayout.textContent =  formatter(totals)
          console.log(totalPayoutKey, totals)
        }
      }
    });
  };
}