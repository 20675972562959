import { Controller } from "stimulus";

const optionsTargetsArray = [
  "payInFullDiscount",
  "depositThenBalance",
  "customSchedule",
  "thirdParty"
];

export default class extends Controller {
  static targets = [
    "paymentOptionsDescription",
    "paymentOptions",
    ...generateTargets(optionsTargetsArray)
  ];

  connect() {
    optionsTargetsArray.forEach((option) => this.toggleOption(option))
    optionsTargetsArray.forEach((option) => this.toggleOption(option))
    this.setPaymentOptionsDescription()
  }

  setPaymentOptionsDescription() {
    const paymentOptionsDescription = this.paymentOptionsDescriptionTarget;
    let description = [];
    optionsTargetsArray.forEach(option => {
      const checkbox = this.getTarget(option, "checkbox");
      const paymentDescription = this.getTarget(option, "description");
      const isChecked = checkbox ? checkbox.checked : false;
      if (isChecked) description.push(descriptionByOption(option))
    })
    paymentOptionsDescription.innerHTML = description.join(', ')
  }

  toggleOption(option, event) {
    const checkbox = this.getTarget(option, "checkbox");
    const collapse = this.getTarget(option, "collapse");
    const button = this.getTarget(option, "collapseBtn");
    const defaultValue = this.getTarget(option, "defaultValue");
    const description = this.getTarget(option, "description");
    const icon = this.getTarget(option, "icon");

    if (checkbox && event) $(checkbox).attr('checked', !checkbox.checked)
    const isChecked = checkbox ? checkbox.checked : false;

    if (isChecked) {
      icon.classList = 'fa fa-check text-teal fa-lg mt-1'
    } else {
      icon.classList = 'fa-regular fa-square fa-lg mt-1'
      $(collapse).collapse("hide");
    }

    if (collapse) {
      collapse.querySelectorAll('input').forEach(input => {
        input.disabled = !isChecked;
      });
    }

    if (button) button.classList.toggle("d-none", !isChecked);
    if (description) description.classList.toggle("text-muted", !isChecked);
    if (defaultValue) defaultValue.classList.toggle("text-muted", !isChecked);

    this.setPaymentOptionsDescription();
    eval(`this.${option}Description()`);
  }

  togglePreference(event) {
    let option = event?.currentTarget?.dataset?.practicePaymentOptionsTarget?.split("Preference")
    if (!option) return;
    option = option[0];
    const preference = this.getTarget(option, "preference");
    const defaultValue = this.getTarget(option, "defaultValue");
    if (preference && event) $(preference).attr('checked', !preference.checked)
    const preferenceValue = preference ? preference.checked : false;
    defaultValue.innerHTML = preferenceValue ? 'ON' : 'OFF'
  }

  payInFullDiscountDescription() {
    const _payInFullDiscountDescription = this.payInFullDiscountDescriptionTarget;
    const _payInFullDiscount = this.discountTarget;
    const _payInFullDiscountMethod = this.discountMethodTarget;
    const minAmount = this.discountMinAmountTarget.value;
    const methodValue = $(_payInFullDiscountMethod).find('input[type="radio"]:checked').val()
    const discountValue = _payInFullDiscount.value

    if (methodValue == 'flat_rate') {
      _payInFullDiscountDescription.innerHTML = `<p>Flat Discount $${discountValue}</p><p> Enabled upon minimum $${minAmount}</p>`
    }
    else {
      _payInFullDiscountDescription.innerHTML = `<p>Discount: ${discountValue}%</p><p> Enabled upon minimum $${minAmount}</p>`
    }
  }

  payInFullDiscountToggle(event) {
    this.toggleOption('payInFullDiscount', event)
  }

  depositThenBalanceDescription() {
    const _depositThenBalanceDescription = this.depositThenBalanceDescriptionTarget;
    const _depositThenBalance = this.depositTarget;
    const _depositThenBalanceMethod = this.depositMethodTarget;
    const minAmount = this.depositMinAmountTarget.value;
    const methodValue = $(_depositThenBalanceMethod).find('input[type="radio"]:checked').val()
    const discountValue = _depositThenBalance.value

    if (methodValue == 'flat_rate') {
      _depositThenBalanceDescription.innerHTML = `<p>Minimum Deposit: $${discountValue}</p><p> Enabled upon minimum $${minAmount}</p>`
    }
    else {
      _depositThenBalanceDescription.innerHTML = `<p>Minimum Deposit: ${discountValue}%</p><p> Enabled upon minimum $${minAmount}</p>`
    }
  }

  depositThenBalanceToggle(event) {
    this.toggleOption('depositThenBalance', event)
  }

  depositThenBalanceToggle(event) {
    this.toggleOption('depositThenBalance', event)
  }

  customScheduleDescription() {
    const _customScheduleDescription = this.customScheduleDescriptionTarget;
    const gap = this.gapTarget.value;
    const instalment = this.instalmentTarget.value;
    const duration = this.durationTarget.value;
    const minAmount = this.customScheduleMinAmountTarget.value;

    _customScheduleDescription.innerHTML = `<p>${instalment} Instalments, ${gap} ${duration} Apart</p><p> Enabled upon minimum $${minAmount}</p>`
  }

  customScheduleToggle(event) {
    this.toggleOption('customSchedule', event)
  }

  thirdPartyDescription(event) {
    const financingOptions = [
      { months: 6, checkboxTarget: this.sixMonthFinancingTarget },
      { months: 12, checkboxTarget: this.twelveMonthFinancingTarget },
      { months: 18, checkboxTarget: this.eighteenMonthFinancingTarget },
      { months: 24, checkboxTarget: this.twentyFourMonthFinancingTarget }
    ];

    const checkedOptions = financingOptions.filter(option => option.checkboxTarget && option.checkboxTarget.checked);
    const checkedMonths = checkedOptions.map(option => option.months);

    if (checkedMonths.length === 0) {
      this.thirdPartyDescriptionTarget.innerHTML = `None`;
    }
    else if (checkedMonths.length === 1) {
      this.thirdPartyDescriptionTarget.innerHTML = `Only ${checkedMonths[0]} months Plan`;
    } else {
      this.thirdPartyDescriptionTarget.innerHTML = `${checkedMonths.slice(0, -1).join(', ')} or ${checkedMonths.slice(-1)} months Plan`;
    }
  }

  thirdPartyToggle(event) {
    this.toggleOption('thirdParty', event)
  }

  getTarget(section, type) {
    return this.targets.find(`${section}${capitalize(type)}`);
  }

  getAction(section, type) {
    return this.actions.find(`${section}${capitalize(type)}`);
  }
}

function targetsByOption(option) {
  switch (option) {
    case 'payInFullDiscount':
      return ["discountMethod", "discount", "discountMinAmount"];
      break;
    case 'depositThenBalance':
      return ["depositMethod", "deposit", "depositMinAmount"];
      break;
    case 'customSchedule':
      return ["instalment", "gap", "duration", "customScheduleMinAmount"];
      break;
    case 'thirdParty':
      return [
        "sixMonthFinancing",
        "sixMonthFinancingMinAmount",
        "twelveMonthFinancing",
        "twelveMonthFinancingMinAmount",
        "eighteenMonthFinancing",
        "eighteenMonthFinancingMinAmount",
        "twentyFourMonthFinancing",
        "twentyFourMonthFinancingMinAmount"];
      break;
    default:
      return [];
      break;
  }
}

function generateTargets(sections) {
  const types = ["preference", "checkbox", "collapse", "collapseBtn", "icon", "description", "defaultValue"];
  return sections.flatMap(section => targetsByOption(section).concat(types.map(type => `${section}${capitalize(type)}`)));
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function titleize(str) {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function descriptionByOption(option) {
  switch (option) {
    case 'payInFullDiscount':
      return 'Pay-in full discount'
      break;
    case 'depositThenBalance':
      return 'Deposit then Balance'
      break;
    case 'customSchedule':
      return 'Payment Plan'
      break;
    case 'thirdParty':
      return 'Financing'
      break;
    default:
      'None'
      break;
  }
}
