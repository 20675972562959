(->
  class Menu
    initialize: ->
      active_child = $('ul.menu li.has-active')
      active_child.closest('li.has-child').addClass('has-active has-open')

      $('.menu-item.has-child.has-open a.menu-link:first span.badge').hide()

      $('.menu-item.has-child').click ->
        if $(@).hasClass('has-open')
          $(@).find('a.menu-link:first span.badge').show()
        else
          $(@).find('a.menu-link:first span.badge').hide()

  window.Menu = new Menu()

).call this
