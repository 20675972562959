import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tabLink", "tabContent", "radioBtn"];
  static values = {
    tabName: String,
    tab: String
  }

  connect() {
    if (!this.hasTabNameValue)
      this.tabNameValue = 'tab'
    this.update_url_path(this.get_tab(this.tabNameValue).trim())

    this.tabLinkTargets.forEach((tabLink) => {
      tabLink.addEventListener("click", this.switchTab.bind(this));
    });
  }

  update_url_path(tab_name) {
    var _path = this.set_params(tab_name)
    history.pushState(null, '', _path)
    this.updateSideUrls()
  }

  set_params(tab_name) {
    var searchParams = new URLSearchParams(window.location.search)
    searchParams.set(this.tabNameValue, tab_name)
    var newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`
    return newRelativePathQuery
  }

  get_tab(tab) {
    var searchParams = new URLSearchParams(window.location.search)
    tab = searchParams.get(tab)
    if (!tab) { tab = this.tabValue }
    return tab
  }

  ChangeUrl(e) {
    var tab_name = e.target.attributes.href.value.split('#')[1]
    if (!tab_name) { tab_name = this.get_tab(this.tabNameValue) }

    this.update_url_path(tab_name.trim())
  }

  updateSideUrls() {
    $("#day_navigation").find('a').each(function () {
      var src = this.href;
      src = Shared.push_param_to_url(src, this.tabNameValue);
      this.href = src;
    });
  }

  switchTab(event) {
    event.preventDefault();
    const linkTag = event.currentTarget.querySelector('a')
    const targetId = linkTag.getAttribute("href").substring(1);

    this.tabLinkTargets.forEach((tabLink) => {
      tabLink.classList.remove("active", "show");
    });
    this.tabContentTargets.forEach((tabContent) => {
      tabContent.classList.remove("active", "show");
    });

    event.currentTarget.classList.add("active", "show");
    document.getElementById(targetId).classList.add("active", "show");
  }

  switchRadioOption(event) {
    this.radioBtnTargets.forEach((btn) => {
      btn.classList.remove("active", "show");
    });

    event.currentTarget.classList.add("active", "show");
  }
}
