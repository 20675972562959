import { Controller } from "stimulus"

export default class extends Controller{
  static values = {
    id: String
  }

  connect() {
    $(this.element).find("select:not(.no-select2)").select2();
    this.inlineTreamentPlan()

    $(this.element).find("select").on("change", () => {
      this.assignNestedValues();
    });
  }

  assignNestedValues() {
    if ($("#referral-case-patient-id").find("select").length > 0)
      $("#treatment-plan-patient-id").val($("#referral-case-patient-id").find("select").val());

    else
      $("#treatment-plan-patient-id").val($("#referral-case-patient-id").find("input").val());

    $("#treatment-plan-provider-id").val($("#referral-case-provider-id").val());
  }

  nestedTreatmentPlan(event) {
    $(event.target).addClass("d-none");
    $("#inline-treatment-plan").on('cocoon:after-insert', () => {
       this.assignNestedValues();
    });
  }

  inlineTreamentPlan() {
    $("#inline-treatment-plan").on('cocoon:before-remove', function() {
      $('.add-txp-btn').removeClass('d-none');
    });
  }
}
