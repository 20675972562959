import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["header", "row", "entries"];

  connect() {
    // If you want to trigger a sort on "Volume ($)" by default, do this:
    const volumeHeader = this.element.querySelector("#volume-header");
    if (volumeHeader && !volumeHeader.classList.contains("ascending")) {
      volumeHeader.click();
    }
  }

  sort(event) {
    const header = event.currentTarget;
    const index = header.cellIndex; // Get the column index of the clicked header
    const isAscending = header.classList.contains("ascending");
    const rows = Array.from(this.rowTargets);

    const isNumberColumn = this.isNumberColumn(index);

    rows.sort((rowA, rowB) => {
      const cellA = rowA.cells[index].textContent.trim();
      const cellB = rowB.cells[index].textContent.trim();

      // If the column is a number (like Volume), parse and sort as numbers
      if (isNumberColumn) {
        const numA = parseFloat(cellA.replace(/[\$,]/g, ""));
        const numB = parseFloat(cellB.replace(/[\$,]/g, ""));
        return isAscending ? numA - numB : numB - numA;
      } else {
        // For non-numeric columns, use string comparison
        return isAscending ? cellA.localeCompare(cellB) : cellB.localeCompare(cellA);
      }
    });

    const tbody = this.element.querySelector("tbody");
    rows.forEach((row) => tbody.appendChild(row)); // Re-append rows to table body after sorting

    this.updateHeaderIcons(header, isAscending); // Update the icon to reflect sorting
  }

  isNumberColumn(index) {
    // Define the index of columns that should be sorted as numbers (Volume column)
    const numberColumns = [2]; // The index of the "Volume" column
    return numberColumns.includes(index);
  }

  updateHeaderIcons(header, isAscending) {
    this.headerTargets.forEach((h) => {
      h.classList.remove("ascending", "descending");
      const icon = h.querySelector("i");
      if (icon) {
        icon.classList.replace("fa-sort-up", "fa-sort");
        icon.classList.replace("fa-sort-down", "fa-sort");
      }
    });

    header.classList.toggle("ascending", !isAscending);
    header.classList.toggle("descending", isAscending);

    const icon = header.querySelector("i");
    if (icon) {
      icon.classList.replace("fa-sort", isAscending ? "fa-sort-down" : "fa-sort-up");
    }
  }
}
