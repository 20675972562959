(->
    class Search
        initialize: ->
            $input = $('*[data-behavior="autocomplete"]')
            options =
                url: (phrase) ->
                    '/searches/search?q=' + phrase
                categories: [
                    {
                        listLocation: "patients",
                        header: "<h6 class='list-group-header d-flex justify-content-between mt-2'>
                                    <span>Patients</span> <a href='/patients' class='font-weight-normal'>Show more</a>
                                </h6>"
                    },
                    {
                        listLocation: "employees",
                        header: "<h6 class='list-group-header d-flex justify-content-between mt-2'>
                                    <span>Employees</span> <a href='/employees' class='font-weight-normal'>Show more</a>
                                </h6>"
                    },
                    {
                        listLocation: "affiliate_organizations",
                        header: "<h6 class='list-group-header d-flex justify-content-between mt-2'>
                                    <span>Affiliate Organizations</span> <a href='/affiliate_organizations' class='font-weight-normal'>Show more</a>
                                </h6>"
                    },
                    {
                        listLocation: "practices",
                        header: "<h6 class='list-group-header d-flex justify-content-between mt-2'>
                                    <span>Practices</span> <a href='/practices' class='font-weight-normal'>Show more</a>
                                </h6>"
                    },
                    {
                        listLocation: "client_prospects",
                        header: "<h6 class='list-group-header d-flex justify-content-between mt-2'>
                                    <span>Client Prospects</span> <a href='/client_prospects' class='font-weight-normal'>Show more</a>
                                </h6>"
                    },
                ]
                getValue: 'name'
                ajaxSettings:
                    method: "GET"
                    complete: ->
                        $('.easy-autocomplete + #search-bar-loader').hide()
                template:
                    type: 'custom'
                    method: (value, item) ->
                        "<div class='list-group-item py-2 d-flex'>
                          <a href=" + item.link + " class='stretched-link' data-remote=" + $input.attr('data-remotable') + "><span class='sr-only'>Go to search result</span></a>
                          <div class='user-avatar user-avatar-sm bg-muted'>
                            " + item.image + "
                          </div>
                          <div class='ml-2'>
                            <div class='mb-n1'>" + "#{item.name}" + "</div>
                          </div>
                        </div>"
                minCharNumber: 3
                requestDelay: 400,

            $input.easyAutocomplete options

    window.Search = new Search()

).call this
