import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "checkboxIcon"]

  toggle() {
    console.log('toggling')
    const isChecked = !this.checkboxTarget.checked;

    console.log(isChecked)

    if (isChecked) {
      this.checkboxIconTarget.classList = 'fa fa-check text-teal fa-lg mt-1'
    } else {
      this.checkboxIconTarget.classList = 'fa-regular fa-square fa-lg mt-1'
    }

    this.checkboxTarget.checked = isChecked;
  }
}