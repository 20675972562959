import { Sortable, MultiDrag } from 'sortablejs';

Sortable.mount(new MultiDrag());

(->
  class MultiSortableList
    collective_init: (target_class) ->
      this.sortable_constructor(target) for target in document.getElementsByClassName(target_class);

    get_treatment_ids: (evt) ->
      _treatment_ids = []
      if evt.items.length > 0
        evt.items.forEach (_item) ->
          _treatment_ids.push(_item.dataset.id)
      else
        _treatment_ids.push(evt.item.dataset.id)

      return _treatment_ids

    sortable_constructor: (element) ->
      that = this

      Sortable.create element,
        group: 'shared',
        multiDrag: true,
        animation: 150
        selectedClass: "sortable-selected"
        fallbackOnBody: true
        invertSwap : true
        onAdd: (evt) ->
          method = "POST"
          if evt.to.dataset.type == evt.from.dataset.type
            method = "PATCH"

          $.ajax
            type: method
            url: evt.to.dataset.url
            dataType: 'script'
            data: { ids: that.get_treatment_ids(evt) }
            success: (results) -> 

  window.MultiSortableList = new MultiSortableList()

).call this
