import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["reload"]

  connect() {
    this.setDisableSubmitButtons()
    this.element.addEventListener('submit', this.handleSubmit.bind(this))
  }

  reloadTargetConnected(){
    this.setDisableSubmitButtons(false)
    this.element.addEventListener('submit', this.handleSubmit.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('submit', this.handleSubmit.bind(this))
  }

  handleSubmit(event) {
    this.setDisableSubmitButtons(true)
  }

  setDisableSubmitButtons(value) {
    const buttons = this.element.querySelectorAll('input[type="submit"], button[type="submit"]')
    buttons.forEach(button => button.disabled = value)
  }
}
