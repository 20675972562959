(->
  class Charts
    initialize: ->
      loadData($('#goals_container a[data-toggle="tab"]')[0])

      $('#goals_container a[data-toggle="tab"]').on 'shown.bs.tab', (e) ->
        loadData(this)
        return

    loadData = (that) ->
      return if $(that).data('rendered')

      $(that).data('rendered', true)
      target = $(that).data('active')
      href = $(that).data('href')
      request = $(that).data('request')

      $.ajax
        type: 'GET'
        url: href
        data: tab: target
        dataType: request
        success: ({ data }) ->
          switch target
            when 'pace'
              Goals.populateFusionCharts(data[0])
              Goals.populateFusionCharts(data[1])
            else
              break
          return


  window.Charts = new Charts()

).call this
