import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    id: String
  }

  connect() {
    $(this.element).find("select:not(.no-select2)").select2();
    this.inlineReferral()
  }

  nestedReferral(event) {
    $(event.target).addClass("d-none");
    $("#inline-referral").on('cocoon:after-insert', () => {
      this.assignNestedValues();
    });
  }

  inlineReferral() {
    $("#inline-referral").on('cocoon:before-remove', function () {
      $('.add-referral-btn').removeClass('d-none');
    });
  }
}
