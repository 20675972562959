import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    msgtype: String,
    message: String
  }

  connect(){
    toastr.options.closeButton = false;
    toastr.options.progressBar = true;
    toastr.options.positionClass = 'toast-top-left';
    toastr.options.timeOut = 1500;

    let msg_type = this.msgtypeValue;
    let message = this.messageValue;

    if(msg_type == 'notice')
      toastr.success(message);

    else if(msg_type == 'alert')
      toastr.error(message);

    else
      toastr.info(message);
  }
}
