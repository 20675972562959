import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["radio", "link"];

  connect() {
    this.radioTargets.forEach(radio => {
      radio.addEventListener('change', this.handleChange.bind(this));
    });
  }

  handleChange(event) {
    let participantType = event.target.value;
    let linkButton = this.linkTargets.find(link => link.dataset.participantType === participantType);
    if (linkButton) {
      linkButton.click();
    }
  }
}
