(->
  window.CreateMonthGoal or  (window.CreateMonthGoal = {})

  CreateMonthGoal.init = ->
    $ ->       
      $("#month_goal_monthpickr").change ->
        date = new Date(this.value);
        $("#month").val(date.getMonth() + 1); #getMonth() starts from 0
        $("#year").val(date.getFullYear())
        
).call this
