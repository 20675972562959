import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["frame"];

  connect() {
    $(this.frameTarget).html('');
  }

  showLoader(event) {
    const frameId = $(event.target).closest('turbo-frame').attr('id');
    this.insertLoader(frameId);
  }

  hideLoader(event) {
    const frameId = $(event.target).closest('turbo-frame').attr('id');
    this.removeLoader(frameId);
  }

  insertLoader(frameId) {
    $(`#${frameId}`).html(Shared.get_loader());
  }

  removeLoader(frameId) {
    $(`#${frameId}`).html('');
  }
}
