import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["collapse"]

  connect() {
    this.toggleBorder()
    const targetId = this.collapseTarget.getAttribute('href')
    const $targetElement = $(targetId)
    $targetElement.on('shown.bs.collapse', this.addBorder.bind(this))
    $targetElement.on('hidden.bs.collapse', this.removeBorder.bind(this))
  }

  toggleBorder() {
    if ($(this.collapseTarget).attr('aria-expanded') == 'true') {
      this.addBorder()
    } else {
      this.removeBorder()
    }
  }

  addBorder() {
    this.element.classList.add('border-active')
  }

  removeBorder() {
    this.element.classList.remove('border-active')
  }
}
