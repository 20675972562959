import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ 'field', 'map', 'google_place_id', 'latitude', 'longitude',
										 'city', 'state', 'zip_code', 'street_address', 'street_address_2',
										 'opening_hours', 'name',
										 'tenant_title', 'time_zone'
									 ]

	connect() {
		if (typeof(google) != "undefined") {
			this.initMap()
		}
	}

	initMap() {
		this.map = new google.maps.Map(this.mapTarget, {
			center: new google.maps.LatLng(this.data.get("latitude") || 39.5, this.data.get("longitude") || -98.35),
			zoom: (this.data.get("latitude") == null ? 4 : 15)
		})

		this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
		this.autocomplete.bindTo('bounds', this.map)
		this.autocomplete.setFields(['address_components', 'place_id', 'geometry', 'opening_hours', 'icon', 'name'])
		this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

		this.marker = new google.maps.Marker({
			map: this.map,
			anchorPoint: new google.maps.Point(0, -29)
		})
	}

	placeChanged() {
		let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
		let place = this.autocomplete.getPlace()

		if (place.name && this.tenant_titleTargets.length > 0) {
			this.tenant_titleTargets[0].value = place.name
			$("#maps-holder").removeClass("d-none")
		}

		if (!place.geometry) {
			window.alert(`No details available for input: ${place.name}`)
			return
		}

		if (place.geometry.viewport) {
			this.map.fitBounds(place.geometry.viewport)
		} else {
			this.map.setCenter(place.geometry.location)
			this.map.setZoom(17)
		}

		this.marker.setPosition(place.geometry.location)
		this.marker.setVisible(true)

		this.latitudeTarget.value = place.geometry.location.lat()
		this.longitudeTarget.value = place.geometry.location.lng()
		this.google_place_idTarget.value = place.place_id,

		// clear values before input
		this.street_addressTargets[0].value = ''
		this.zip_codeTargets[0].value = ''

		for (const component of place.address_components) {
		// @ts-ignore remove once typings fixed
		const componentType = component.types[0];

			switch (componentType) {
				case "street_number": {
					this.street_addressTargets[0].value = `${component.long_name} ${this.street_addressTargets[0].value}`;
					this.street_addressTargets[1].innerHTML = this.street_addressTargets[0].value;
				break;
				}

				case "route": {
					this.street_addressTargets[0].value += component.short_name;
					this.street_addressTargets[1].innerHTML = this.street_addressTargets[0].value;
				break;
				}

				case "postal_code": {
					this.zip_codeTargets[0].value = `${component.long_name}${this.zip_codeTargets[0].value}`;
					this.zip_codeTargets[1].innerHTML = this.zip_codeTargets[0].value;
				break;
				}

				case "postal_code_suffix": {
					this.zip_codeTargets[0].value = `${this.zip_codeTargets[0].value}-${component.long_name}`;
					this.zip_codeTargets[1].innerHTML = this.zip_codeTargets[0].value;
				break;
				}

				case "locality":
					this.cityTargets[0].value = component.long_name;
					this.cityTargets[1].innerHTML = this.cityTargets[0].value;
				break;

				case "administrative_area_level_1": {
				 this.stateTargets[0].value = component.short_name;
				 this.stateTargets[1].innerHTML = this.stateTargets[0].value;
				break;
				}

				case "subpremise":
					this.street_address_2Targets[0].value = component.long_name;
					this.street_address_2Targets[1].innerHTML = this.street_address_2Targets[0].value;
				break;
			}
		}

		if (place.name) {
			this.nameTargets[0].value = place.name
			this.nameTargets[1].innerHTML = place.name
		}

		if (place.opening_hours) {
			place.opening_hours.periods.forEach(function (value) {
				if (value.open)
					$(`#${days[value.open.day]}_opens_at`).val(`${value.open.time.substring(0,2)}:${value.open.time.substring(2,4)}:00`)
				if (value.close) 
					$(`#${days[value.close.day]}_closes_at`).val(`${value.close.time.substring(0,2)}:${value.close.time.substring(2,4)}:00`)
			});
			this.opening_hoursTarget.innerHTML = place.opening_hours.weekday_text
		}
	}

	keydown(event) {
		if (event.key == "Enter") {
			event.preventDefault()
		}
	}
}
