( ->
  class SecretShopperCall
    select_pass_fail = (attribute) ->
      if $('#' + attribute + '-option input:checked').val() == 'fail'
        $('#' + attribute).removeClass('d-none')
      else if $('#' + attribute + '-option input:checked').val() == 'pass'
        $('#' + attribute).addClass('d-none')

    pass_fail_change = (attribute) ->
      $('#' + attribute + '-option :input').change ->
        select_pass_fail(attribute)

    init: ->
      attributes_array = ['proper_greeting_grade', 'promoting_office_grade', 'handling_insurance_grade', 'pleasant_ending_grade']

      $("#offering_primetime_grade-option").click ->
        $('#offering_primetime_grade').removeClass('d-none')

      for attr in attributes_array
        select_pass_fail(attr)
        pass_fail_change(attr)

      $('#explanation_switcher :input').change ->
        if $('#explanation_switcher :checked').val() == 'on'
          $('#ssc_explanation').removeClass('d-none')
        else
          $('#ssc_explanation').addClass('d-none')

  window.SecretShopperCall = new SecretShopperCall();
).call this
