(->
  class RenderAction

    init: (path, params) ->
      $.ajax
        type: 'GET'
        url: path
        data: params
        dataType: 'script'
        success: (result) ->
          $(".modal-backdrop").remove();
          $('#mainModalDrawer').modal 'show'
          return
      return


  window.RenderAction = new RenderAction()

).call this
