import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["maskedInput"];


  formatInputs(event) {
    this.maskedInputTargets.forEach(input => {
      const maskType = input.dataset.masktype;
      let value = $(input).cleanVal();

      if (maskType === 'expiryDate') {
        if (value) {
          const parts = value.match(/(\d{2})(\d{2})/);
          value = `20${parts[2]}-${parts[1]}-01`;
        }
      }

      input.value = value;
    });
  }
}
