import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["targetFrom", 'clickable']

  async fetchResources(e) {
    const $currentTarget = $(e.currentTarget);
    $currentTarget.addClass('disabled-view');

    const icon = e.currentTarget.querySelector("i");
    $(icon).removeClass('fa-sync');
    $(icon).addClass('fa-spinner fa-spin');

    const url = $currentTarget.data("resourceFetchUrlValue");
    const urls = typeof url === 'string' ? [url] : url;

    try {
      await Promise.all(urls.map(async (element) => {
        try {
          await $.ajax({
            type: 'GET',
            url: element,
            dataType: 'script'
          });
        } catch (error) {
          console.error(error);
        }
      }));
    } catch (error) {
      console.error(error);
    } finally {
      $currentTarget.removeClass('disabled-view');
      $(icon).removeClass('fa-spinner fa-spin');
      $(icon).addClass('fa-sync');
    }

    const $lazyTurboFrame = $($currentTarget).parents('turbo-frame[loading="lazy"]');
    if (this.hasTargetFromTarget) {
      const $targetForm = $(this.targetFromTarget).find("form");
      if ($targetForm.length > 0) {
        $targetForm[0].requestSubmit();
      }
    } else if (this.hasClickableTarget) {
      const $clickable = $(this.clickableTarget)
      if ($clickable.length > 0) {
        $clickable.trigger('click');
      }
    } else if ($lazyTurboFrame.length > 0) {
      const turbo_frame = $lazyTurboFrame[0];
      const src = $(turbo_frame).attr('src');
      turbo_frame.innerHTML = Shared.get_loader();
      turbo_frame.setAttribute("src", null);
      turbo_frame.setAttribute("src", src);
    } else {
      console.error("Target form not found.");
    }
  }
}
