(->
  class Datatable
    initialize: ->
      dataTable = $('.table').DataTable({
        dom: "<'row'<'col-sm-12 col-md-6'li><'col-sm-12 col-md-6'f>><'table-responsive'tr><'row align-items-center'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 d-flex justify-content-end'p>>",
        deferRender: true,
      })
      document.addEventListener 'turbo:before-cache', ->
        if dataTable != null
          dataTable.destroy()
          dataTable = null
        return

  window.Datatable = new Datatable()

).call this
