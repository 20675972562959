(->
  class ToggleCheckbox
    initialize: ->
      if $(".show-toggle-checkbox").is(':checked')
        ToggleCheckbox.SelectOption("show-toggle-checkbox")

      if $(".hide-toggle-checkbox").is(':checked')
        ToggleCheckbox.SelectOption("hide-toggle-checkbox")

      $('.toggle-checkbox').click ->
        if $(this).is(':checked')
          $show = $($(this).data('show'))
          $hide = $($(this).data('hide'))
          $clear = $($(this).data('clear'))

          if $show.length > 0
            $show.removeClass 'd-none'
            $("#appointment-time, #indicators").removeClass 'd-none'

          if $hide.length > 0
            $hide.addClass 'd-none'
            $("#appointment-time, #indicators").addClass 'd-none'

          if $clear.length > 0
            $clear.val ''


    ToggleCheckbox.SelectOption = (toggle) ->
      $show = $($("."+ toggle.toString()).data('show'))
      $hide = $($("."+ toggle.toString()).data('hide'))
      $clear = $($("."+ toggle.toString()).data('clear'))

      if $show.length > 0
        $show.removeClass 'd-none'
        $("#appointment-time, #indicators").removeClass 'd-none'

      if $hide.length > 0
        $hide.addClass 'd-none'
        $("#appointment-time, #indicators").addClass 'd-none'

      if $clear.length > 0
        $clear.val ''

  window.ToggleCheckbox = new ToggleCheckbox()

).call this
