(->
  window.RecordsRelease or  (window.RecordsRelease = {})

  RecordsRelease.init = ->
    $ ->
      $('#records_release_send_to_patient, #records_release_send_to_office').change ->
        if $("##{$(this).attr('id')}").prop("checked")
          $("##{$(this).attr('id')}_fields").removeClass('d-none');
        else
          $("##{$(this).attr('id')}_fields").addClass('d-none');

).call this
