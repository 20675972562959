import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {

  connect() {
    let eventType = this.data.get("eventTypeValue")
    let resourceType = this.data.get("resourceTypeValue")
    let employeeId = this.data.get("employeeIdValue")
    let practiceId = this.data.get("practiceIdValue")

    function isCalendlyEvent(e) {
      return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
    };

    window.addEventListener("message", function (e) {
      if (isCalendlyEvent(e)) {
        if (e.data.event == "calendly.event_scheduled") {
          switch (eventType) {
            case 'installation':
              installation(e.data.payload)
              break;
            case 'demo':
              demo(e.data.payload)
              break;

            default:
              break;
          }
        }
      }
    });

    function installation(payload) {
      axios({
        method: 'GET',
        headers: {
          "Authorization": `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjYxNDMxNTY5LCJqdGkiOiI4MGNhMjdiZi02MmJkLTQ4MTItODUwZC00MmIxOGYwN2M2N2QiLCJ1c2VyX3V1aWQiOiJFRkFFQUhYUDRFV0xJNTRNIn0.kfWjoEnYuNLDcpcEphvY8S0wr7JPwZ5FcxN7ABALFUFWso-0yT0rlANzCaG0pMaimFOo3qglFUUZRONFpENX0g`
        },
        url: `${payload.event.uri}`
      })
        .then(({ data }) => {
          let { resource } = data

          axios({
            method: 'POST',
            url: `/client_meetings/scheduled`,
            data: {
              client_meeting: {
                practice_id: practiceId,
                created_by_id: employeeId,
                meeting_at: resource.start_time,
                meeting_notes: resource.name
              }
            }
          }).finally(() => {
            switch (resourceType) {
              case 'practice':
                window.location.href = "/registrations/onboardings"
                break;
              case 'onboardings':
                location.reload()
                break;

              default:
                break;
            }
          })
        })
    }

    function demo(payload) {
      axios({
        method: 'GET',
        headers: {
          "Authorization": `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjYxNDMxNTY5LCJqdGkiOiI4MGNhMjdiZi02MmJkLTQ4MTItODUwZC00MmIxOGYwN2M2N2QiLCJ1c2VyX3V1aWQiOiJFRkFFQUhYUDRFV0xJNTRNIn0.kfWjoEnYuNLDcpcEphvY8S0wr7JPwZ5FcxN7ABALFUFWso-0yT0rlANzCaG0pMaimFOo3qglFUUZRONFpENX0g`
        },
        url: `${payload.invitee.uri}`
      })
        .then(({ data }) => {
          let { resource } = data
          axios({
            method: 'POST',
            url: `/client_prospects/demo`,
            data: {
              client_prospect: {
                contacts_attributes: [
                  {
                    first_name: resource.first_name,
                    last_name: resource.last_name,
                    email: resource.email
                  }
                ]
              }
            }
          })
        })

    }
  }
}
