import { Controller } from "stimulus"

export default class extends Controller{

  connect() {
    const info = JSON.parse(this.data.get("infoValue"));

    //Time Series DataTable formation
    if ("schema" in info){
      const fusionTable = new FusionCharts.DataStore().createDataTable(info["data"], info["schema"]);
      info["dataSource"]["data"] = fusionTable
    }
    Goals.populateFusionCharts(info);
  }
}
