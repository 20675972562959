import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["map"];

  connect() {
    const mapElement = this.mapTarget;

    if (!mapElement) {
      console.error("Map element not found");
      return;
    }

    const map = new google.maps.Map(mapElement, {
      center: { lat: 39.957612875, lng: -74.2122808125 },
      zoom: 9,
    });

    const practices = JSON.parse(this.data.get("practicesValue"));
    const locations = JSON.parse(this.data.get("locationsValue"));

    practices.forEach((practice) => {
      const image = {
        url: practice["image_url"],
        scaledSize: new google.maps.Size(32, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 32),
      };

      const latitude = parseFloat(practice["latitude"]);
      const longitude = parseFloat(practice["longitude"]);

      const marker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: map,
        title: practice['title'],
        icon: image,
      });

      const infoWindow = new google.maps.InfoWindow({
        content: `<p>${practice['title']}</p>`
      });

      marker.addListener('click', function () {
        map.panTo(this.getPosition());
        map.setZoom(15);
        infoWindow.open(map, marker);
      });
    });

    // Center point of all markers
    const bounds = new google.maps.LatLngBounds();
    practices.forEach((practice) => {
      bounds.extend(new google.maps.LatLng(practice["latitude"], practice["longitude"]));
    });

    // Don't zoom in too far on only one marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      bounds.extend(new google.maps.LatLng(bounds.getNorthEast().lat() + 0.1, bounds.getNorthEast().lng() + 0.1));
      bounds.extend(new google.maps.LatLng(bounds.getNorthEast().lat() - 0.1, bounds.getNorthEast().lng() - 0.1));
    }

    map.fitBounds(bounds);

    if (locations) {
      const heatmapData = locations.map((location) => {
        const latitude = parseFloat(location["latitude"]);
        const longitude = parseFloat(location["longitude"]);
        return new google.maps.LatLng(latitude, longitude)
      });

      const heatmap = new google.maps.visualization.HeatmapLayer({
        data: heatmapData,
        map: map,
        radius: 20,
        maxIntensity: 10
      });
    }
  }
}
