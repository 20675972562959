import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['checkBox', 'nextBtn']

  connect() {
    MultiSortableList.collective_init('dd-list')

    this.toggleCheckBox()
  }

  toggleCheckBox() {
    if (this.hasNextBtnTarget) {
      if (this.hasCheckBoxTarget && this.checkBoxTargets.some(v => v.checked)) {
        $(this.nextBtnTarget).attr('disabled', false)
      } else {
        $(this.nextBtnTarget).attr('disabled', true)
      }
    }
  }
}
