import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "hiddenField", "form"];

  setHiddenFieldValue(e) {
    const value = e.currentTarget.dataset["value"];
    const hiddenField = this.hiddenFieldTarget;
    const form = this.formTarget

    hiddenField.value = value;
    form.requestSubmit();
  }
}
