window.readURL = function readURL(input, selector = "img_prev") {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $(`#${selector}`).attr('src', e.target.result)
    };

    reader.readAsDataURL(input.files[0]);
  }
}
