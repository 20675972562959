import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const element = $(this.element);
    if (element.hasClass('patients-list')) {
      const patientList = element
      console.log('Select2 initialized on patient list:', patientList);
      patientList.select2({
        allowClear: true,
        minimumInputLength: 2,
        dataType: 'json',
        ajax: {
          url: "/patients/search",
          type: "GET",
          dataType: "JSON",
          delay: 250,
          data: function (params) {
            return {
              q: params.term
            };
          },
          processResults: function (data) {
            return {
              results: $.map(data, function (value) {
                return {
                  id: value.id,
                  text: value.name
                };
              })
            };
          }
        }
      });

      const patientText = patientList.data("patient-text");
      const selectedPatientId = patientList.data("selected-patient-id");
      if (typeof patientText !== 'undefined' && typeof selectedPatientId !== 'undefined') {
        const option = new Option(patientText, selectedPatientId, true, true);
        patientList.append(option).trigger('change');
        patientList.trigger('select2:select');
      }
    } else if (element.hasClass('practices-list')) {
      const practicesList = element
      console.log('Select2 initialized on practice list:', practicesList);
      practicesList.select2({
        allowClear: true,
        minimumInputLength: 2,
        dataType: 'json',
        ajax: {
          url: "/clients/search",
          type: "GET",
          dataType: "JSON",
          delay: 250,
          data: function (params) {
            return {
              q: params.term
            };
          },
          processResults: function (data) {
            return {
              results: $.map(data, function (value) {
                return {
                  id: value.id,
                  text: value.name
                };
              })
            };
          }
        }
      });

      const practiceText = practicesList.data("practice-text");
      const selectedPracticeId = practicesList.data("selected-practice-id");
      if (typeof practiceText !== 'undefined' && typeof selectedPracticeId !== 'undefined') {
        const option = new Option(practiceText, selectedPracticeId, true, true);
        practicesList.append(option).trigger('change');
        practicesList.trigger('select2:select');
      }
    }
  }

  disconnect() {
    $(this.element).select2('destroy');
  }
}
