(->
  window.PerioCase or  (window.PerioCase = {})

  PerioCase.init = ->
    $ ->
      $('#insurance-claim-yes').click ->
        $("#insurance-claim").removeClass('d-none');
        if $('#perio-case-result').data('source') != "approved"
          $(".ready-treatment-btn").prop("disabled", true);

      $('#insurance-claim-no').click ->
        $("#insurance-claim").addClass('d-none');
        $(".ready-treatment-btn").prop("disabled", false);
).call this
