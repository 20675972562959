(->
  class Comment
    initialize: ->
      $.ajax
        url: "/comments"
        type: "GET"
        dataType: 'json'
        success: (data) ->
          document.getElementById('comment_section').innerHTML = data.entries
          document.getElementById('comment_pagination_section').innerHTML = data.pagination
          # if data.entries.length > 0
          #   $('#notifier').addClass('has-notified')
          # return

  window.Comment = new Comment()

).call this
