class CurrencyConverter
  constructor: (@locale = 'en-US') ->

  convertCurrencyToFloat: (currency) ->
    return 0 unless currency?
    currency = currency.toString() if typeof currency isnt 'string'
    parseFloat(currency.replace(/[^0-9.-]+/g, ''))

  convertFloatToCurrency: (number) ->
    number.toLocaleString @locale, style: 'currency', currency: 'USD'

  capitalize: (s) ->
    if s then s[0].toUpperCase() + s.slice(1) else ""

window.converter = new CurrencyConverter()
