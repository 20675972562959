import { Controller } from "stimulus"

export default class extends Controller {

  handleChange(e) {
    let selectedTier = e.target.value;

    reloadForm({ tier: selectedTier });
  }
}

export function reloadForm(params) {
  $("#billing_form").html(`
      <div class="spinner-border text-info my-4" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    `)

  $('#practice-pricing').attr("disabled", true)

  $.ajax({
    type: 'GET',
    url: `${window.location.pathname}/steps/checkout`,
    dataType: 'script',
    data: params,
    success: function () { }
  })
}
