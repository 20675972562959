( ->
  class Patient
    Patient.update_url_path = (tab_name) ->
      _path = set_params(tab_name)
      history.pushState null, '', _path

    set_params = (tab_name) ->
      if 'URLSearchParams' of window
        searchParams = new URLSearchParams(window.location.search)
        searchParams.set('association', tab_name)
        newRelativePathQuery = "#{window.location.pathname}?#{searchParams.toString()}"
        return newRelativePathQuery

    init: ->
      $('.patient-tabs').click ->
        Patient.update_url_path(this.dataset.associationName)

        $('#myTabDetails .active').removeClass('active');
        $(this).addClass('active');

    fetch_patients: ->

    search_patients: ->
      searchInput = document.getElementById('search-patient-input')

      searchInput.addEventListener 'keyup', (event) ->
        console.log(event.target.value)
        $.ajax
          url: "/patients"
          type: "GET"
          dataType: "script"
          data: { q: event.target.value }
          success: (data) ->
            return
        return


  window.Patient = new Patient();
).call this
