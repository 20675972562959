(->
  class DateRangeDropdown
    initialize: ->
      selected = $("##{$('.date-range-dropdown #date-range-type').val()}")
      if selected
        $('.date-range-dropdown .date-range-label').text selected.data('label')

      $('.date-range-dropdown .range-option').click ->
        $($(this).data('lteq')).val($(this).data('end'))
        $($(this).data('gteq')).val($(this).data('start'))
        $('.date-range-dropdown #date-range-type').val($(this).prop('id'))
        $('.date-range-dropdown .date-range-label').text($(this).data('label'))
        if $('.date-range-dropdown').data('submit') != ''
          Rails.fire($($('.date-range-dropdown').data('submit')).get(0), 'submit')

      $('.date-range-dropdown .range-custom').click ->
        $('.date-range-dropdown #date-range-type').val($(this).prop('id'))
        $('.date-range-dropdown .date-range-label').text($(this).data('label'))

  window.DateRangeDropdown = new DateRangeDropdown()

).call this
