import { Controller } from "stimulus"

// Connects to data-controller="aftercare-log"
export default class extends Controller {
  static targets = ["appointment", "patient", "appointmentList", "controllerName", "pickerType", "imposterAppointmentId", "date"]

  static values = {
    autoSelect: Boolean,
    selectedAppointmentId: String
  }

  connect() {
    this.reloadData()
  }

  reloadData() {
    var update_appointment_list;

    $(this.appointmentTarget).addClass('d-none');

    $(this.appointmentTarget.getElementsByTagName("input")).prop('disabled', true);

    update_appointment_list = (patient_id, resource_gid, controller_name) => {
      var date, imposter_appointment_id = null

      if (this.hasDateTarget)
        date = $(this.dateTarget).val()

      if (this.hasImposterAppointmentIdTarget)
        imposter_appointment_id = $(this.imposterAppointmentIdTarget).val()

      if (patient_id !== '') {
        $('#track_by_radio_group').removeClass('d-none');
        $(this.appointmentTarget).removeClass('d-none');
        $(this.appointmentTarget.getElementsByTagName("input")).prop('disabled', false);
        var that = this
        var patient_id = patient_id

        return $.ajax({
          type: 'GET',
          url: '/appointments/patient_appointments',
          dataType: 'html',
          data: {
            patient_id: patient_id,
            resource_gid: resource_gid,
            controller_name: $(that.controllerNameTarget).val(),
            picker_type: $(that.pickerTypeTarget).val(),
            imposter_appointment_id: imposter_appointment_id,
            date: date
          },
          success: function (result) {
            $(that.appointmentListTarget).html(result);
            $(that.patientTarget).val(patient_id).trigger('change')


          },
          complete: () => {
            var inputElements = this.appointmentListTarget.querySelectorAll('input[type="radio"]');
            if (inputElements.length > 0) {
              if (this.hasSelectedAppointmentIdValue) {
                let inputElement = $(inputElements).filter(`[value="${this.selectedAppointmentIdValue}"]`);
                inputElement.prop('checked', true).change();
                let labelElement = inputElement.closest('label');
                if (labelElement.length > 0) {
                  this.selectTreatmentPlannableAttribute(labelElement[0]);
                }
                $('#appointments-list').trigger('click');
              } else if (this.hasAutoSelectValue && this.autoSelectValue) {
                var inputElement = $(inputElements).filter('[data-appointment-status="completed"]');
                if (!inputElement.length) inputElement = $(inputElements);
                inputElement = $(inputElement[0])

                inputElement.prop('checked', true).trigger('change')
                let labelElement = $(inputElement).closest('label');
                if (labelElement.length > 0) {
                  this.selectTreatmentPlannableAttribute(labelElement[0]);
                }
                $('#appointments-list').trigger('click');
              }
            } else {
              $(that.appointmentListTarget).html("<p class='font-italic'>No Appointments found</p>");
            }
          }
        });
      }
    };

    if ($(this.patientTarget).val() !== "") {
      update_appointment_list($(this.patientTarget).val(), $(this.patientTarget).attr('data-appointment-attachable-gid'), $(this.controllerNameTarget).val());
    }

    $('.patients-list').change(function () {
      return update_appointment_list(this.value, $(this.patientTarget).attr('data-appointment-attachable-gid'), $(this.controllerNameTarget).val());
    });
  }

  fetchAppointments(e) {
    $(e.currentTarget).addClass('disabled-view')
    var icon = e.currentTarget.querySelector("i");
    $(icon).removeClass('fa-sync');
    $(icon).addClass('fa-spinner fa-spin');

    var url = $(e.currentTarget).data("appointmentFetchUrlValue")
    var that = this
    var current_element = e.currentTarget
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      success: function (result) {
        that.reloadData()
        $(current_element).removeClass('disabled-view')
        $(icon).removeClass('fa-spinner fa-spin');
        $(icon).addClass('fa-sync');
      }
    });
  }

  selectTreatmentPlannable = (event) => {
    this.selectTreatmentPlannableAttribute(event.currentTarget);
  }

  selectTreatmentPlannableAttribute = (element) => {
    const { treatmentplannableId, treatmentplannableType, providerId, providerName, appointmentAt } = element.dataset;
    this.setFormValues(treatmentplannableId, treatmentplannableType, providerId);

    if (this.hasDateTarget)
      $(this.dateTarget).val(appointmentAt).trigger('change')

    const appointmentContent = $(element).html();
    const sanitizedContent = this.sanitizeAppointmentContent(appointmentContent);
    this.showSelected(sanitizedContent);
  }

  sanitizeAppointmentContent(appointmentContent) {
    const $content = $(appointmentContent);
    $content.find('.custom-control-label').removeClass('custom-control-label');
    $content.find('input[type="radio"]').remove();
    return $content.prop('outerHTML');
  }

  setFormValues(treatmentplannableId, treatmentplannableType, providerId) {
    const treatmentPlannableIdField = document.querySelector("#treatment_plan_treatment_plannable_id");
    const treatmentPlannableTypeField = document.querySelector("#treatment_plan_treatment_plannable_type");
    const treatmentPlannableProviderIdField = document.querySelector('#treatment_plan_provider_id');
    const hasPresentationFields = document.querySelector('#has_presentation_fields');

    if (treatmentPlannableIdField && treatmentPlannableTypeField) {
      treatmentPlannableIdField.value = treatmentplannableId;
      treatmentPlannableTypeField.value = treatmentplannableType;
      var $providerInput = $("#treatment-doctor-provider-id :input");

      if (providerId) {
        treatmentPlannableProviderIdField.value = providerId;
      }
      else {
        treatmentPlannableProviderIdField.value = "";
      }

      $providerInput.val(providerId || "");
      $providerInput.trigger('change');
      $providerInput.prop("disabled", false)
      $providerInput.prop("required", true)

      $("#treatment-doctor-provider-id").removeClass("d-none")

      $('#presentation-type-id').val("has_presentation").trigger("change")
      $('#orphan-treatment-plan-submit').removeClass('d-none')
    }
  }

  showSelected(appointmentContent) {
    $('#selectedAppointmentContent').html(appointmentContent);
  }
}
