import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["duration", "allRecords"]

  clear() {
    this.durationTarget.value = "";
    this.allRecordsTarget.value = "true"
  }

  handleFocus() {
    this.allRecordsTarget.value = "false"
  }

  handleInputChange() {
    this.allRecordsTarget.value = "false"
  }
}