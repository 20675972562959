import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.updateActiveLinks();
    Menu.initialize();
  }

  updateActiveLinks() {
    const currentPath = window.location.pathname;

    this.linkTargets.forEach((link) => {
      const linkPath = link.pathname;
      const closestLi = link.closest("li");

      if (closestLi && linkPath) {
        if (currentPath.includes(linkPath)) {
          closestLi.classList.add("has-active");
        } else {
          closestLi.classList.remove("has-active");
        }
      }
    });
  }
}
