(->
  window.Referral or  (window.Referral = {})

  Referral.init = ->
    $ ->
      $("#referral-existing :input").prop('disabled', true)
      $('#referral-existing-radio').change ->
        if $(this).prop("checked")
          $('#referral-existing').removeClass('d-none')
          $('#referral-new').addClass('d-none')
          $("#referral-new :input").prop('disabled', true)
          $("#referral-existing :input").prop('disabled', false)

      $('#referral-new-radio').change ->
        if $(this).prop("checked")
          $('#referral-new').removeClass('d-none')
          $('#referral-existing').addClass('d-none')
          $("#referral-existing :input").prop('disabled', true)
          $("#referral-new :input").prop('disabled', false)

).call this
