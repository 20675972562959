import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $(this.element).find("select:not(.no-select2)").select2();
    datetime_picker.init();
    datetime_picker.date_input();
    datetime_picker.month_input("month_goal_monthpickr");
    CreateMonthGoal.init();
  }
}
