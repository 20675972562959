import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['percentageInput', 'numberInput'];

  connect() {
    console.log("controller connected")
  }

  numberFieldChange(event) {
    const { value, dataset: { goalValue } } = event.target

    const inPercentage = (value / goalValue) * 100
    this.percentageInputTarget.value = inPercentage.toFixed(1)
  }

  percentageFieldChange(event) {
    const { value, dataset: { goalValue } } = event.target
    const inNumber = goalValue * value/100
    this.numberInputTarget.value = Math.round(inNumber);
  }
}
