practices = require('./practice_controller')

(->
  window.StripeElements or (window.StripeElements = {})

  StripeElements.init = (stripe_publishable_key, controller_name, connected_account) ->
    stripe = Stripe(stripe_publishable_key, { stripeAccount: connected_account})

    payment_element_form = document.getElementById('payment-element-form')
    secretValue = payment_element_form.getAttribute('data-secret')
    return_url = payment_element_form.getAttribute('data-return-url')

    appearance =
      theme: 'stripe'

    elements = stripe.elements({ clientSecret: secretValue, appearance: appearance })

    # Create the Payment Element
    paymentElement = elements.create('payment')
    paymentElement.mount('#payment-element')

    payment_element_form.addEventListener 'submit', (event) ->
      event.preventDefault()
      StripeElements.loading true

      stripe.confirmPayment({
        elements: elements,
        confirmParams: {
          return_url: return_url
        }
      }).then ({ error }) ->
        StripeElements.loading false
        if error
          StripeElements.showError(error.message)

  StripeElements.loading = (isLoading) ->
    if isLoading
      document.querySelector('button').disabled = true
      document.querySelector('#spinner').classList.remove 'hidden'
      document.querySelector('#button-text').classList.add 'hidden'
    else
      document.querySelector('button').disabled = false
      document.querySelector('#spinner').classList.add 'hidden'
      document.querySelector('#button-text').classList.remove 'hidden'

  StripeElements.showError = (errorMsgText) ->
    StripeElements.loading false
    errorMsgContainer = document.querySelector("#card-error-container")
    errorMsgContainer.classList.remove('d-none')
    errorMsg = document.querySelector("#card-error-msg")
    errorMsg.textContent = errorMsgText
    setTimeout (->
      errorMsgContainer.classList.add('d-none')
    ), 8000

).call this
