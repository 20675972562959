$(function () {
  let modal_holder_selector = "#modal-holder";
  const modal_selector = ".modal";

  $(document).on("click", "a[data-modal]", function () {
    $(this).tooltip("dispose")
    const location = $(this).attr("href");

    butContent = this.innerHTML
    this.innerHTML = Shared.get_loading()
    // Load modal dialog from server
    $.get(location, data => {
      if ($($(data)[0]).is("#mainModal")) {
        $('#mainModalDrawer').modal('hide')
        modal_holder_selector = "#modal-holder";
        // Remove old modal backdrop
        $(".modal-backdrop").remove();
        $(this).tooltip("enable")
      }
      else {
        $('#mainModal').modal('hide')
        modal_holder_selector = "#modal-drawer-holder";
        $(this).tooltip("enable")
      }
      this.innerHTML = butContent;
      $(modal_holder_selector)
        .html(data)
        .find(modal_selector)
        .modal();

      flatpickr(`${modal_holder_selector} [data-behavior='flatpickr_date']`, {
        controller: "flatpickr",
        altFormat: "F j, Y",
        dateFormat: "Y-m-d"
      });

      flatpickr(`${modal_holder_selector} [data-behavior='flatpickr_month']`, {
        plugins: [new monthSelectPlugin({})]
      });

      Goals.initializeSelect();
      FrontDeskAppointment.init();
      CreateMonthGoal.init();
      Patient.fetch_patients();
      SortableList.initialize();
      NestableList.initialize();
    });
    return false;
  });

  $(document).on("ajax:success", "form[data-modal]", function (event) {
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader("Location");
    if (url) {
      // Redirect to url
      window.location = url;
    } else {
      // Remove old modal backdrop
      $(".modal-backdrop").remove();
      // Update modal content
      const modal = $(data)
        .find("body")
        .html();
      $(modal_holder_selector)
        .html(modal)
        .find(modal_selector)
        .modal();
    }

    return false;
  });
});
