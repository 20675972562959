import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['tabs', 'customBadgeInput']

  connect() {
    const customBadgeInputs = this.customBadgeInputTargets
    const checkedInput = customBadgeInputs.find(input => input.checked);

    if (checkedInput) {
      const { value } = checkedInput
      this.toggleForm(value)
    } else {
      this.toggleForm('system_badge')
    }
  }

  updateView(e) {
    const { value } = e.target
    this.toggleForm(value)
  }

  toggleForm(value) {
    this.tabsTargets.forEach((tab) => {
      if(tab.id == value) {
        tab.classList.add('fade', 'show', 'active')
        tab.classList.remove('d-none')
        tab.querySelectorAll('input, select, textarea').forEach(input => input.disabled = false)
      }else {
        tab.classList.add('d-none')
        tab.classList.remove('fade', 'show', 'active')
        tab.querySelectorAll('input, select, textarea').forEach(input => input.disabled = true)
      }
    })
  }
}