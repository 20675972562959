import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $("select:not(.no-select2)").select2()
    datetime_picker.time_input();
    LabCase.manage_conditions()
  }
}
