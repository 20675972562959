// app/javascript/controllers/custom_schedule_fields_controller.js
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fullPrice', 'remainingPrice', 'submitBtn', 'addPaymentBtn'];

  connect() {
    const fieldEnabled = this.element.dataset.fieldEnabled
    $(this.element.getElementsByTagName("input")).prop('disabled', fieldEnabled != "true");

    this.addCocoon = this.addCocoon.bind(this);
    $(this.element).on("cocoon:after-insert", this.addCocoon);

    this.removeCocoon = this.removeCocoon.bind(this);
    $(this.element).on("cocoon:after-remove", this.removeCocoon);

    this.observer = new MutationObserver(this.checkCocoons.bind(this));
    const targetElement = this.fullPriceTarget;
    const config = { attributes: true, attributeFilter: ["value"] };
    this.observer.observe(targetElement, config);

    this.checkCocoons();
    if ($('#txp_input_type_dropdown input[type="radio"]:checked')?.val() == 'quick_input') {
      $(".txp-input-type-tab-quick_input").tab('show')
      $(".txp-input-type-tab-quick_input").show()
      $(".txp-input-type-tab-builder").hide()
      $('#txp-input-type-field').val('quick_input')
      $('.txp-input-type-tab-quick_input :input').prop('disabled', false)
      $('.txp-input-type-tab-builder :input').prop('disabled', true)
    }
    if ($('#presentation-type-id').val() == "no_presentation") {
      $('#presentation-type-id').val("no_presentation")
      $('#no_presentation_fields :input').prop('disabled', false)
      $('#no_presentation_fields :input:not(#treatment_plan_no_presentation_notes)').prop('required', true)
      $("#no_presentation_fields, #no_presentation_footer").removeClass('d-none')
      $('#has_presentation_fields :input').prop('disabled', true)
      $('#has_presentation_fields select').prop('disabled', true)
      $("#has_presentation_fields, #has_presentation_footer").addClass('d-none')
    }
  }

  disconnect() {
    this.observer.disconnect();
  }

  addCocoon() {
    const dateInputs = Array.from(this.element.querySelectorAll('.active.due-at-input')).filter(dateInput => {
      const parentDiv = dateInput.closest('.nested-fields');
      return parentDiv && getComputedStyle(parentDiv).display !== 'none';
    });

    // Find the last date input in the list
    const lastDateInput = dateInputs[dateInputs.length - 2];
    if (lastDateInput) {
      const lastDateValue = lastDateInput.value;

      if (isValidDate(lastDateValue)) {
        const newDate = new Date(lastDateValue);
        newDate.setDate(newDate.getDate() + 1); // Increment the date by one day

        const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = newDate.toLocaleDateString('en-US', options);

        // Set the new date value to the newly added cocoon
        const newDateInput = dateInputs[dateInputs.length - 1];
        if (newDateInput) {
          newDateInput.dataset.flatpickrDefaultDate = `${newDate.getFullYear()}-${padZero(newDate.getMonth() + 1)}-${padZero(newDate.getDate())}`
          newDateInput.value = formattedDate;
          newDateInput?.nextSibling?.classList?.remove('is-invalid');
        }
      }
    }

    this.checkCocoons();
  }

  removeCocoon() {
    this.checkCocoons();
  }

  changeAmount() {
    this.checkCocoons();
  }

  changeDate() {
    this.checkCocoons();
  }

  checkCocoons() {
    const amountInputs = Array.from(this.element.querySelectorAll('.amount-input')).filter(amountInput => {
      const parentDiv = amountInput.closest('.nested-fields');
      return parentDiv && getComputedStyle(parentDiv).display !== 'none';
    });

    const dateInputs = Array.from(this.element.querySelectorAll('[type="text"].due-at-input')).filter(dateInput => {
      const parentDiv = dateInput.closest('.nested-fields');
      return parentDiv && getComputedStyle(parentDiv).display !== 'none';
    });

    let fullPrice = parseFloat(this.fullPriceTarget.value);
    let remainingPrice = fullPrice;

    let isValid = true;
    let isCocoonAdded = false; // Flag to track if any cocoon is added

    const selectedDates = new Set(); // Set to store selected dates

    amountInputs.forEach((amountInput, index) => {
      const dateInput = dateInputs[index];
      const amount = parseFloat(amountInput.value);

      // Add error class if the amount is not valid
      if (isNaN(amount) || amount <= 0 || amount > remainingPrice) {
        // amountInput.classList.add('is-invalid');
        isValid = false;
      } else {
        // amountInput.classList.remove('is-invalid');
      }
      // Check if the date is valid
      if (dateInput && dateInput.value && isValidDate(dateInput.value)) {
        // dateInput.classList.remove('is-invalid');
        const currentDate = new Date(dateInput.value);
        // const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        // const formattedDate = currentDate.toLocaleDateString('en-US', options);
        const currentDateStr = `${currentDate.getFullYear()}-${currentDate.getMonth()}-${currentDate.getDate()}`

        // Check if the current date is greater than or equal to the current time
        if (currentDate < new Date()) {
          // dateInput.classList.add('is-invalid');
          isValid = false;
        }
        // Check if the current date is already selected
        else if (selectedDates.has(currentDateStr)) {
          // dateInput.classList.add('is-invalid');
          isValid = false;
        } else {
          selectedDates.add(currentDateStr); // Add the current date to the set
        }
      } else {
        if (dateInput) {
          // dateInput.classList.add('is-invalid');
        }
        isValid = false;
      }

      if (!isNaN(amount))
        remainingPrice -= amount;

      if (amount > 0) {
        isCocoonAdded = true;
      }
    });

    // Show or hide the "add payment" button based on the remaining price and input validity
    // const addPaymentButton = this.addPaymentBtnTarget
    // // const submitBtn = this.submitBtnTarget
    // if (isValid) {
    //   addPaymentButton.style.display = '';
    // } else {
    //   addPaymentButton.style.display = 'none';
    // }

    // // Show or hide the "add cocoon" button if no cocoon is added
    // if (!isCocoonAdded) {
    //   addPaymentButton.style.display = '';
    // }

    // if (remainingPrice === 0 && isValid) {
    //   addPaymentButton.style.display = 'none';
    //   // submitBtn.classList.remove('d-none');
    // } else {
    //   // submitBtn.classList.add('d-none');
    // }

    this.remainingPriceTarget.value = remainingPrice.toFixed(2);

    const customScheduleDescription = $(this.element).closest('[data-controller="payment-options"]').find('[data-financial-arrangement-target="customScheduleDescription"]');

    if (customScheduleDescription[0]) {
      const paymentLength = amountInputs?.length;
      let scheduleMessage = 'None';
      const amounts = amountInputs.map(input => parseFloat(input.value) || 0);
      const dueDates = dateInputs.map(input => new Date(input.value).getTime());

      const frequencySeconds = dueDates.length > 1 ? Math.floor((dueDates[1] - dueDates[0]) / 1000) : 0;
      const frequencyWords = secondsToWords(frequencySeconds);

      // Calculate future payments
      const firstPayment = amounts.shift() || 0;
      const futurePaymentsCount = amounts.length;
      if (paymentLength == 0) {
        scheduleMessage = 'No payment plan'
      } else if (futurePaymentsCount == 0) {
        scheduleMessage = `$${firstPayment} Today`;
      } else {
        const averageFuturePayment = futurePaymentsCount > 0 ? (amounts.reduce((sum, val) => sum + val, 0) / futurePaymentsCount) : 0;
        const paymentWord = futurePaymentsCount > 1 ? 'payments' : 'payment'
        scheduleMessage = `$${firstPayment} Today, ${futurePaymentsCount} ${paymentWord} of $${averageFuturePayment.toFixed(2)}, ${frequencyWords} apart`;
      }

      customScheduleDescription[0].innerHTML = scheduleMessage;
    }
  }
}

function secondsToWords(seconds) {
  if (seconds <= 0) return 'unknown';

  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (days > 0) return `${days} days`;
  if (hours > 0) return `${hours} hours`;
  if (minutes > 0) return `${minutes} minutes`;

  return `${seconds} seconds`;
}


function isValidDate(dateString) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

function padZero(num) {
  return num < 10 ? `0${num}` : num;
}