( ->
  class Subscription
    add: (_this) ->
      url = "/#{_this.dataset.subscribableType}/#{_this.dataset.subscribableId}/subscriptions"

      $('.subscription-controls-' + $(_this).val()).html Shared.get_loader
      $.ajax
        url: url
        type: "POST"
        dataType: "script"
        data: { subscription: { employee_id: _this.dataset.employeeId }, model_name: _this.dataset.modelName }
        success: (data) ->
          returns
      return

    get: (_this) ->
      if $(_this).attr("aria-expanded") == "true"
        return
      url = "/#{_this.dataset.subscribable_type}/#{_this.dataset.subscribable_id}/subscriptions"

      $.ajax
        url: url
        type: "GET"
        dataType: "script"
        data: { model_name: _this.dataset.subscribable_type }
        success: (data) ->
          return
      return

  window.Subscription = new Subscription();
).call this
