( ->
  class Document
    init: (id = '') ->
      common(id)
      $('form').on 'cocoon:after-insert', (e, inserted_item) ->
        inserted_item.find('select').select2()
        common(inserted_item.find('.timestamp').val());

      $('form').on 'cocoon:before-insert', (e, inserted_item) ->
        new_id = "_#{e.timeStamp}"

        inserted_item.find("#new").attr("name", "picker_option#{new_id}")
        inserted_item.find("#existing").attr("name", "picker_option#{new_id}")

        inserted_item.find("#new_label").attr("for", "new#{new_id}")
        inserted_item.find("#existing_label").attr("for", "existing#{new_id}")

        inserted_item.find("#distribute_to_user_input").attr("name", "scenario_type#{new_id}")
        inserted_item.find("#request_from_staff_input").attr("name", "scenario_type#{new_id}")

        inserted_item.find("#distribute_to_user_label").attr("for", "distribute_to_user_input#{new_id}")
        inserted_item.find("#request_from_staff_label").attr("for", "request_from_staff_input#{new_id}")

        $('<input>').attr({
            type: 'hidden',
            class: 'timestamp',
            value: new_id
        }).appendTo(inserted_item);


        fields = ['distribute_to_user_input', 'distribute_to_user_label', 'request_from_staff_label', 'scenario_type', 'documentable_new_fields_container', 'request_from_staff_input', 'request_from_staff', 'distribute_to_staff', 'upload_new', 'use_existing', 'existing', 'new', 'existing_label', 'new_label']
        fields.forEach (_field) ->
          inserted_item.find("##{_field}").attr("id", "#{_field}#{new_id}")

    common = (id = '') ->
      $("#distribute_to_user_input#{id}").change ->
        if $(this).prop("checked")
          $("#request_from_staff#{id}").addClass('d-none');
          $("#distribute_to_staff#{id}").removeClass('d-none');
          $("#scenario_type#{id}").val("distribute_to_user");

          $("#request_from_staff#{id} :input").prop("disabled", true);
          $("#distribute_to_staff#{id} :input").prop("disabled", false);

          # conditionally disbaled documentable container
          if $("#existing_label#{id}").hasClass('active')
            $("#documentable_new_fields_container#{id} :input").prop("disabled", true);
            $("#distribute_to_staff#{id} :input").prop("disabled", true);

      $("#request_from_staff_input#{id}").change ->
        if $(this).prop("checked")
          $("#request_from_staff#{id}").removeClass('d-none');
          $("#distribute_to_staff#{id}").addClass('d-none');

          $("#scenario_type#{id}").val("request_from_user");
          $("#distribute_to_staff#{id} :input").prop("disabled", true);
          $("#request_from_staff#{id} :input").prop("disabled", false);


          $("#documentable_new_fields_container#{id} :input").prop("disabled", false);

      $("#existing#{id}").change ->
        if $(this).prop("checked")
          $("#use_existing#{id}").removeClass('d-none')
          $("#upload_new#{id}").addClass('d-none')
          $("#use_existing#{id} :input").prop("disabled", false);
          $("#upload_new#{id} :input").prop("disabled", true);

          $("#documentable_new_fields_container#{id} :input").prop("disabled", true);

      $("#new#{id}").change ->
        if $(this).prop("checked")
          $("#upload_new#{id}").removeClass('d-none')
          $("#use_existing#{id}").addClass('d-none')
          $("#upload_new#{id} :input").prop("disabled", false);

          $("#use_existing#{id} :input").prop("disabled", true);

          $("#documentable_new_fields_container#{id} :input").prop("disabled", false);



  window.Document = new Document();
).call this
