import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["uploadedImage", "fileUpload", "outputImage", "embededResponse", "outputPdf", "imageUrl"];

  connect() {
    if (this.uploadedImageTarget.parentElement || !this.fileUploadTarget.dataset.required) {
      this.fileUploadTarget.removeAttribute('required');
    }

    this.fileUploadTarget.addEventListener('change', (event) => {
      this.uploadedImageTarget.classList.add('d-none');
      let output;
      if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png') {
        this.outputImageTarget.classList.remove('d-none');
        this.embededResponseTarget.classList.add('d-none');
        output = this.outputImageTarget;
      } else {
        this.embededResponseTarget.classList.remove('d-none');
        this.outputImageTarget.classList.add('d-none');
        output = this.outputPdfTarget;
      }
      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = () => {
        URL.revokeObjectURL(output.src);
        return;
      };
    });
  }

  captureScreenshot() {
    var displayMediaOptions = {
      video: {
        displaySurface: "window",
      },
      audio: false,
    };
    var that = this
    screenshotCapture();
    async function screenshotCapture() {
      const stream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
      const videoElem = document.createElement("video");
      var image = that.outputImageTarget

      videoElem.srcObject = stream;
      await videoElem.play();
      var canvas = document.createElement("canvas");
      canvas.width = videoElem.videoWidth;
      canvas.height = videoElem.videoHeight;
      canvas.getContext("2d").drawImage(videoElem, 0, 0, canvas.width, canvas.height);
      var base64String = canvas.toDataURL();

      videoElem.srcObject.getTracks().forEach(track => track.stop());
      var uploadeImage = that.uploadedImageTarget
      uploadeImage.innerHTML = '';
      image.src = base64String;
      $(that.imageUrlTarget).val(base64String);
    }
  }

}
