import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.update_url_path(this.get_tab('tab').trim(), this.data.get("clientid"))
  }

  update_url_path(tab_name, employee_id = '') {
    var _path = this.set_params(tab_name, employee_id)
    history.pushState(null, '', _path)
    this.update_all_employees_link()
  }

  update_all_employees_link () {
    $("#clients-list a").each((index, link) => {
      link.href = `${link.href.split("=")[0]}=${this.get_tab('tab')}`
    })
  }

  set_params(tab_name, employee_id) {
    var searchParams = new URLSearchParams(window.location.search)
    searchParams.set('tab', tab_name)
    searchParams.set('client_id', employee_id)
    var newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`
    return newRelativePathQuery
  }

  get_tab(tab) {
    var searchParams = new URLSearchParams(window.location.search)
    return searchParams.get(tab)
  }

  ChangeUrl(e) {
    var client_id = e.target.dataset.consultingRelationshipClientid
    if (!client_id) {client_id = this.get_tab('client_id')}

    var tab_name = e.target.text.toLowerCase()
    if (!tab_name) {tab_name = this.get_tab('tab')}

    this.update_url_path(tab_name.trim(), client_id)
  }
}
