(->
  class Practice

    constructor: ->
      this.tenant_id = null
      this.url = null

    init: (_tenant_id) ->
      this.tenant_id = _tenant_id
      that = this

      $("#practice-show-page").on 'turbo:before-fetch-request', (event) ->
        event.preventDefault()
        event.detail.url.search += "#{(if !Boolean(event.detail.url.search) then '' else '&')}tenant_id=#{that.tenant_id}"
        event.detail.resume()

      $("#practice-show-page").click ->
        $.ajaxSetup
          beforeSend: ->
            unless this.url.includes("/searches") or this.url.includes("/clients")
              this.url += "#{(if this.url.includes('?') then '&' else '?')}tenant_id=#{that.tenant_id}"

  window.Practice = new Practice()

).call this
