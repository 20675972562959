(->
  window.DataTable or  (window.DataTable = {})

  DataTable.initialize = (datatable_id) ->
    $ ->
      $("##{datatable_id}").DataTable({
            "order": [[ 1, "desc" ]],
            "paging": false,
            "searching" : false,
            "bPaginate": false,
            "bInfo": false,
        });

).call this
