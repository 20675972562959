import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    eventUrl: String,
    modalView: Boolean,
    initialView: String,
    defaultDate: String,
    hideHeaders: Boolean,
    showMinMaxSlots: Boolean,
    popoverView: Boolean
  }

  connect() {
    this.calendarInitializer(this.eventUrlValue, this.modalViewValue, this.initialViewValue, this.defaultDateValue, this.hideHeadersValue, this.showMinMaxSlotsValue, this.popoverViewValue)
  }

  calendarInitializer(eventUrl, modalView = true, initialView = 'timeGridDay', defaultDate, hideHeaders = false, showMinMaxSlots = false, popoverViewValue = false) {
    EventCalendar.initialize(eventUrl, modalView, initialView, defaultDate, hideHeaders, showMinMaxSlots, popoverViewValue)
  }
}
